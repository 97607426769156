import React from "react";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { Button, Form, Segment } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { isFormValidationError } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import Input from "../../Input";
import * as yup from "yup";
import HelpText from "../../components/HelpText";

const GET_DATA = gql`
  {
    portalUser {
      person {
        forename
        surname
        niNumber
      }
    }
  }
`;

const profileValidations = yup.object({
  forename: yup.string().required("Required"),
  surname: yup.string().required("Required"),
});

const changePasswordValidations = yup.object({
  currentPassword: yup.string().required("Required"),
  newPassword: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

function ProfileDetails() {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const profileForm = useForm({
    resolver: yupResolver(profileValidations),
  });

  const changePasswordForm = useForm({
    resolver: yupResolver(changePasswordValidations),
  });

  const { loading, refetch } = useQuery(GET_DATA, {
    onCompleted: ({
      portalUser: {
        person: { forename, surname, niNumber },
      },
    }) => {
      profileForm.reset({
        forename,
        surname,
        niNumber,
      });
    },
  });

  const [updatePortalUser, { loading: updating }] = useMutation(
    gql`
      mutation($forename: String!, $surname: String!, $niNumber: String) {
        updatePortalUser(
          forename: $forename
          surname: $surname
          niNumber: $niNumber
        ) {
          person {
            forename
            surname
            niNumber
          }
        }
      }
    `,
    {
      onCompleted: () => {
        addToast(t("Profile updated"), { appearance: "success" });
        refetch();
      },
    }
  );

  const [updatePassword, { loading: updatingPassword }] = useMutation(
    gql`
      mutation($newPassword: String!, $currentPassword: String!) {
        updatePassword(
          newPassword: $newPassword
          currentPassword: $currentPassword
        ) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        addToast(t("Password updated"), { appearance: "success" });
      },
    }
  );

  const onSubmitProfile = ({ forename, surname, niNumber }) => {
    updatePortalUser({
      variables: {
        forename,
        surname,
        niNumber,
      },
    }).catch((error) => {
      error.graphQLErrors.forEach((error) => {
        if (isFormValidationError(error)) {
          profileForm.setError(error.extensions.field, {
            type: "server",
            message: error.message,
          });
        } else {
          addToast(t("Profile not updated"), { appearance: "error" });
        }
      });
    });
  };

  const onSubmitChangePassword = ({ newPassword, currentPassword }) => {
    updatePassword({
      variables: {
        newPassword,
        currentPassword,
      },
    }).catch((error) => {
      error.graphQLErrors.forEach((error) => {
        if (isFormValidationError(error)) {
          changePasswordForm.setError(error.extensions.field, {
            type: "server",
            message: error.message,
          });
        } else {
          addToast(t("Password not updated"), { appearance: "error" });
        }
      });
    });
  };

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <Segment attached="top">
        <h3>
          {t("Profile")}
          <HelpText
              contentArea="Profile"
              contentModule="Profile"
              contentType="HELP"
              contentItem="Profile details"
              accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <FormProvider {...profileForm}>
          <Form onSubmit={profileForm.handleSubmit(onSubmitProfile)}>
            <Input label={t("Forename")} name="forename" />
            <Input label={t("Surname")} name="surname" />
            <Input label={t("NI Number")} name="niNumber" />
            <Button  className={'green'} loading={updating} disabled={updating}>
              {t("Save")}
            </Button>
          </Form>
        </FormProvider>
      </Segment>
      <Segment attached="top">
        <h3>
          {t("Change Password")}
          <HelpText
              contentArea="Profile"
              contentModule="Profile"
              contentType="HELP"
              contentItem="Change Password"
              accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <FormProvider {...changePasswordForm}>
          <Form
            onSubmit={changePasswordForm.handleSubmit(onSubmitChangePassword)}
          >
            <Input
              label={t("New Password")}
              name="newPassword"
              type="password"
            />
            <Input
              label={t("Confirm Password")}
              name="passwordConfirmation"
              type="password"
            />
            <Input
              label={t("Current Password")}
              name="currentPassword"
              type="password"
            />
            <Button  className={'green'} loading={updatingPassword} disabled={updatingPassword}>
              {t("Save")}
            </Button>
          </Form>
        </FormProvider>
      </Segment>
    </React.Fragment>
  );
}

export default ProfileDetails;
