import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Icon, Image } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { v } from "../utils";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  {
    portalUser {
      person {
        forename
        surname
        pupilGuardian {
          pupil {
            pupilId
            forenames
            surname
            birthdate
            yearGroup
            gender
            hasUpcomingMeeting
            hasIncomingCommunication
            currentEstablishment {
              estabName
            }
          }
        }
      }
    }
  }
`;

function Home() {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA);
  const history = useHistory();

  if (!loading) {
    const children = v(data, ["portalUser", "person", "pupilGuardian"], []);
    if (children.length === 0) {
      history.push('/confirm-children');
      return null;
    }
  }

  const getParentsFullName = () => {
    const forename = v(data, ["portalUser", "person", "forename"], "");
    const surname = v(data, ["portalUser", "person", "surname"], "");
    return `${forename} ${surname}`;
  };

  const getChildren = () =>
    v(data, ["portalUser", "person", "pupilGuardian"], []);

  const getProfilePicture = (pupil) => {
    switch (pupil.gender) {
      case "F":
        return "/female.png";
      default:
        return "/male.png";
    }
  };

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <div className="text-4xl tracking-tight leading-10 font-bold pb-2 border-b">
            {`${t('Welcome')} ${getParentsFullName()}`}
            <HelpText
              contentArea="Welcome"
              contentModule="Home"
              contentType="HELP"
              contentItem="Welcome"
              accessLevel="Private"
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row >
        {getChildren().map((pupilGuardian) => (
          <Grid.Column key={pupilGuardian.pupil.pupilId} width={4}>
            <div className="border rounded bg-white shadow text-center" style={{height:'430px'}}>
              <div style={{height:'275px'}}>

                <Image
                  src={getProfilePicture(pupilGuardian.pupil)}
                  size="tiny"
                  centered
                  circular
                  className="my-8"
                />
                <div
                  style={{
                    height: "19px",
                  }}
                >
                  {pupilGuardian.pupil.hasIncomingCommunication && (
                    <Icon name="mail outline" />
                  )}
                  {pupilGuardian.pupil.hasUpcomingMeeting && (
                    <Icon name="calendar alternate outline" />
                  )}
                </div>
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-3">
                    {pupilGuardian.pupil.forenames} {pupilGuardian.pupil.surname}
                  </div>
                  <div
                    style={{
                      height: "90px",
                    }}
                  >
                    <p className="text-gray-700 text-center">
                      {v(
                        pupilGuardian,
                        ["pupil", "currentEstablishment", "estabName"],
                          t("No current School")
                      )}
                    </p>

                    <p className="text-gray-700">
                      {t('Year Group')} {pupilGuardian.pupil.yearGroup}
                    </p>
                  </div>
                </div>
              </div>
                <div className="my-8">
                  <Link
                    to={`/children/${pupilGuardian.pupil.pupilId}`}
                    className="p-2 rounded bg-purple-600 text-white hover:text-white hover:bg-purple-800"
                  >
                    {t('View Details')}
                  </Link>
                </div>
              </div>
              <br />
             <br />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
}

export default Home;
