import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Button, Divider, Form, Grid, Segment, Table} from "semantic-ui-react";
import {v} from "./utils";
import {useTranslation} from 'react-i18next';
import HelpText from "./components/HelpText";
import dateFormatter from "./DateFormatter";
import impulse_nexus_wheel from "./assets/impulse_nexus_wheel_nobg.png";
import {useToasts} from "react-toast-notifications";

const GET_DATA = gql`
  {
    portalUser {
      person {
        pupilGuardiansWithResponsibility {
          pupil {
            pupilId
            forenames
            surname
            birthdate
            yearGroup
          }
        }
      }
    }
  }
`;

const UPDATE_PUPIL_GUARDIAN_RESPONSIBILITY = gql`
  mutation AddPupilsResponsibility($pupilIds: [Int]!) {
    addPupilsResponsibility(pupilIds: $pupilIds) {
      pupilId
    }
  }
`;


function ChildrenConfirmationScreen() {
  const history = useHistory();
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA);
  const { addToast } = useToasts();
  const [linkedChildStatus, setLinkedChildStatus] = useState({});
  const [addPupilsResponsibility, { loading: isUpdating }] = useMutation(
    UPDATE_PUPIL_GUARDIAN_RESPONSIBILITY,
    {
      onCompleted: () => {
        addToast(t("Responsibility added."), { appearance: "success" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Error in adding responsibility."), { appearance: "error" });
        });
      },
    }
  );

  useEffect(() => {
    if (!loading) {
      const children = v(data, ["portalUser", "person", "pupilGuardiansWithResponsibility"], []);
      const linkedChildStatusInit = {};
      children.forEach(({ pupilId }) => {
        linkedChildStatusInit[pupilId + '_check'] = false;
      });
      setLinkedChildStatus(linkedChildStatusInit);
    }
  }, [loading, data]);

  const getChildren = () =>
      v(data, ["portalUser", "person", "pupilGuardiansWithResponsibility"], []);

  const handleCheckBoxClick = (_, { name }) => {
    setLinkedChildStatus(prevState => ({...prevState, [name]: !prevState[name]}))
  }

  const confirmButtonEnabled = () =>
    Object.values(linkedChildStatus).reduce((acc, currVal) => acc || currVal, false)

  return (
    <div className="flex flex-col min-h-screen">
      <div className="text-center">
        <Grid centered columns={4}>
          <Grid.Column>
            <div className="flex items-center p-5">
              <img src={impulse_nexus_wheel} alt="impulse_nexus_logo" />
            </div>
          </Grid.Column>
        </Grid>

        <div className="text-4xl font-bold mb-6 text-gray-600">
          {t("Parent's Portal - Confirm Children to Link")}
        </div>
        <Divider />
      </div>
      <Segment>
        <div className="text-center">
          <div className="text-xl mb-5 mt-2 font-bold text-gray-600">
            <HelpText
              contentArea="Check & Add Linked Pupils"
              contentModule="Login"
              contentType="BIGTEXT"
              contentItem="Add Child to Portal Help Text"
              accessLevel="Private"
            />
            {t("According to our records, the following children have a relationship with you.")}
          </div>
          <div className="text-xl mb-2 font-bold text-gray-600">
            {t("Please confirm which children you would like to manage using your Parents Portal account.")}
          </div>
        </div>
      </Segment>
      <Segment>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Forename")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Surname")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Date of Birth")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Year Group")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Link Child")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getChildren().map(({ pupil : { pupilId, forenames, surname, birthdate, yearGroup }}) =>
              (
                <Table.Row key={pupilId}>
                  <Table.Cell>{forenames}</Table.Cell>
                  <Table.Cell>{surname}</Table.Cell>
                  <Table.Cell>
                    {dateFormatter(birthdate)}
                  </Table.Cell>
                  <Table.Cell>{yearGroup}</Table.Cell>
                  <Table.Cell>
                    <Form.Checkbox
                      name={pupilId + '_check'}
                      toggle
                      checked={linkedChildStatus[pupilId + '_check']}
                      onClick={handleCheckBoxClick}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
        <div className="flex-grow text-center">
          <Button
            className={"blue w-2/5"}
            type={"submit"}
            disabled={!confirmButtonEnabled()}
            loading={loading}
            onClick={() => {
              addPupilsResponsibility({ variables: { pupilIds: Object.keys(linkedChildStatus).filter(key => linkedChildStatus[key] === true).map(key => key.split('_')[0]) }})
                .then(() => history.push("/"));
            }}
          >
            {t("Confirm")}
          </Button>
        </div>
      </Segment>
      <Grid style={{ marginTop: "auto" }}>
        <Grid.Row>
          <Grid.Column width={12} className="mx-auto">
            <Segment >
              <div className="text-center">
                <div className="text-1xl mb-3 font-bold ">
                  <HelpText
                    contentArea="Registration"
                    contentModule="Request Access"
                    contentType="BIGTEXT"
                    contentItem="Contact Us Help Text Footer"
                    accessLevel="Public"
                  />
                  <span className="text-blue-500">
                    {t("Having problems?")}
                  </span>
                </div>
                <div className="text-1xl mb-3 text-gray-600 ">
                  {t("If you are having problem completing registration or if some of your information is wrong, please contact us at:")}
                </div>
                <div className="text-1xl font-bold text-blue-500">
                  helpme@caci.gov.co.uk
                </div>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default ChildrenConfirmationScreen;
