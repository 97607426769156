import React from "react";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function GenericErrorMessage() {
  const { t } = useTranslation();
  return (
    <Message error>
      <Message.Content>
          {t('There was an error processing your request.')}
      </Message.Content>
    </Message>
  );
}

export default GenericErrorMessage;
