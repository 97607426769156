import React, { createContext, useCallback, useReducer } from "react";
import axios from "axios";

const initialState = { publicContent: {} };

export const PublicPortalContext = createContext(initialState);

const portalReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_PUBLIC_CONTENT":
      return {
        ...state,
        publicContent: { ...state.publicContent, ...action.content }
      };
    default:
      return state;
  }
};

const reduceCb = (acc, d) => ({
  ...acc,
  [`${d.contentModule}-${d.contentTypeCode}-${d.contentArea}-${d.contentItem}`]: {
    ...d,
    contentType: d.contentTypeCode
  }
});

export const PortalContentPublicProvider = ({ children }) => {
  const contentLoading = {};
  const [state, dispatch] = useReducer(portalReducer, initialState);

  const fetchPortalContent = useCallback(
    ({ contentModule, contentType, contentArea }) => {
      const key = `${contentModule}-${contentType}-${contentArea}`;
      if (!contentLoading[key]) {
        contentLoading[key] = true;
        axios
          .get(`/api/help-text/${contentModule}/${contentType}/${contentArea}`)
          .then(({ data }) => {
            dispatch({
              type: "ADD_PUBLIC_CONTENT",
              content: data?.reduce(reduceCb, {})
            });
          })
          .catch(console.log)
          .finally(() => {
            contentLoading[key] = false;
          });
      }
    },
    [contentLoading]
  );

  return (
    <PublicPortalContext.Provider value={{ ...state, fetchPortalContent }}>
      {children}
    </PublicPortalContext.Provider>
  );
};