import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "semantic-ui-react";

function TextArea({ label = "", name = "", readOnly = false }) {
  const { control, errors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FormField error={!!errors[name]}>
          <label>{label}</label>
          <textarea
            readOnly={readOnly}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          {!!errors[name] && (
            <span className="text-red-700 text-xs">{errors[name].message}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default TextArea;
