import React, { useState } from "react";
import { gql } from "apollo-boost";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination, Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { lookupFormatter, lookupToOptions, v } from "../../utils";
import LookupCode from "../../LookupCode";
import dateFormatter from "../../DateFormatter";
import { useTranslation } from "react-i18next";
import MeetingDetails from "./MeetingDetails";
import MeetingContributionDetails from "./EducationHistory";
import HelpText from "../../components/HelpText";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../Input";
import { mergeDeepRight } from "ramda";

const GET_MEETINGS = gql`
  query GetMeetings(
    $pupilId: Int!
    $page: Int!
    $size: Int!
    $locationType: String
    $panelType: String
    $panelDate: LocalDate
  ) {
    lookupCodes(codeTypes: [6145, 5181]) {
      codeType
      codeValue
      codeDescription
    }
    portalUser {
      person {
        serviceDefinition {
          id
        }
      }
    }
    pupil(pupilId: $pupilId) {
      serviceDefinition {
        panelSubjects(
          page: $page
          size: $size
          panelType: $panelType
          locationType: $locationType
          panelDate: $panelDate
        ) {
          number
          totalPages
          content {
            ... on PanelSubject {
              subjectId
              panelSubjectFamily {
                invited
              }
              panel {
                panelId
                panelDate
                panelType
                startTime
                duration
                locationType
                virtualUrl
                virtualText
                address {
                  formatted
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TableWrapper = styled.div`
  overflow-y: scroll;
  max-height: 550px;
  padding: 0;
  margin: 0;
  border: 1px solid #e2e8f0;
  border-radius: 0.285rem;

  table.ui.table {
    border: none;
  }

  table thead {
    position: sticky;
    top: 0;
  }
`;

function MeetingList({ Dropdowns }) {
  const searchForm = useForm();
  const { t } = useTranslation();
  const [titles, setTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { pupilId } = useParams();

  const submitSearch = ({ panelType, locationType, panelDate }) => {
    fetchMore({
      variables: {
        panelType: panelType,
        locationType: locationType,
        panelDate: panelDate ? panelDate : null,
        size: pageSize,
        page: 0,
        pupilId: pupilId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          pupil: {
            serviceDefinition: {
              panelSubjects:
                fetchMoreResult.pupil.serviceDefinition.panelSubjects,
            },
          },
        });
      },
    });
  };

  const { loading, data, fetchMore } = useQuery(GET_MEETINGS, {
    variables: {
      pupilId,
      page: 0,
      size: pageSize,
      panelType: "",
      locationType: "",
      panelDate: null,
    },
  });

  if (loading) return <LoadingMessage />;

  if (v(data, "pupil.serviceDefinition.panelSubjects.content",[]).length <= 0)
    return (
      <div>
        {" "}
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input label={t("Date")} name={"panelDate"} type="date" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="panelType"
                    label={t("Type")}
                    options={lookupToOptions(5181)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="locationType"
                    label={t("Location Type")}
                    options={lookupToOptions(6145)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                      trigger={
                        <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                panelDate: "",
                                panelType: "",
                                locationType: "",
                              });
                            }}
                        >
                          <Icon name="remove circle" />
                        </Button>
                      }
                      content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  const yesNoValues = [
    { codeValue: "Y", codeDescription: "Yes" },
    { codeValue: "N", codeDescription: "No" },
    { codeValue: null, codeDescription: "No" },
    { codeValue: "", codeDescription: "No" },
  ];

  return (
    <React.Fragment>
      <Switch>
        <Route path="/children/:pupilId/meetings/:subjectId">
          <MeetingDetails />
        </Route>
        <Route path="/children/:pupilId/meetings/:subjectId/cont/:dd">
          <MeetingContributionDetails />
        </Route>
        <div>
          <FormProvider {...searchForm}>
            <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Input label={t("Date")} name={"panelDate"} type="date" />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Dropdowns
                      name="panelType"
                      label={t("Type")}
                      options={lookupToOptions(5181)(
                        v(data, "lookupCodes", [])
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Dropdowns
                      name="locationType"
                      label={t("Location Type")}
                      options={lookupToOptions(6145)(
                        v(data, "lookupCodes", [])
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "5" }}>
                    <br />
                    <Button icon color={"green"} type={"submit"}>
                      <Icon name="search" />
                    </Button>
                    <Popup
                        trigger={
                          <Button
                              icon
                              color={"red"}
                              type="submit"
                              onClick={() => {
                                searchForm.reset({
                                  panelDate: "",
                                  panelType: "",
                                  locationType: "",
                                });
                              }}
                          >
                            <Icon name="remove circle" />
                          </Button>
                        }
                        content={t("Clear")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </FormProvider>
          <TableWrapper>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Start Time")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Duration")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Location Type")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Invited")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                  <Table.HeaderCell>
                    <HelpText
                      contentArea="Child Record"
                      contentModule="Children"
                      contentType="HELP"
                      contentItem="Meetings"
                      accessLevel="Private"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {v(data, "pupil.serviceDefinition.panelSubjects.content", [])
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.panel.panelDate) - new Date(a.panel.panelDate)
                  )
                  .map((panelSubject) => (
                    <Table.Row key={panelSubject.subjectId}>
                      <Table.Cell>
                        {dateFormatter(panelSubject.panel.panelDate)}
                      </Table.Cell>
                      <Table.Cell>
                        <LookupCode
                          codeType={5181}
                          codeValue={panelSubject.panel.panelType}
                        />
                      </Table.Cell>
                      <Table.Cell>{panelSubject.panel.startTime}</Table.Cell>
                      <Table.Cell>{panelSubject.panel.duration}</Table.Cell>
                      <Table.Cell>
                        <LookupCode
                          codeType={6145}
                          codeValue={panelSubject.panel.locationType}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {lookupFormatter(
                          yesNoValues,
                          v(panelSubject, "panelSubjectFamily.invited", "")
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          as={Link}
                          className={"blue"}
                          to={`/children/${pupilId}/meetings/${panelSubject.subjectId}`}
                        >
                          {t("View")}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </TableWrapper>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <br />
                <br />
                <Pagination
                  boundaryRange={1}
                  activePage={
                    data.pupil.serviceDefinition?.panelSubjects.number + 1
                  }
                  siblingRange={1}
                  totalPages={
                    data.pupil.serviceDefinition?.panelSubjects.totalPages
                  }
                  onPageChange={(e, { activePage }) =>
                    fetchMore({
                      variables: {
                        page: activePage - 1,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        return mergeDeepRight(prev, {
                          pupil: {
                            serviceDefinition: {
                              panelSubjects:
                                fetchMoreResult.pupil.serviceDefinition
                                  .panelSubjects,
                            },
                          },
                        });
                      },
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column width={2} style={{ textAlign: "center" }}>
                <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                  {t("Record per Page")}
                </label>
                <Segment style={{ padding: "10px" }}>
                  <Dropdown
                    value={pageSize}
                    name="pageSize"
                    options={[
                      { value: 10, text: "10" },
                      { value: 25, text: "25" },
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                    ]}
                    onChange={(_, { value }) => setPageSize(value)}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Switch>
    </React.Fragment>
  );
}

export default MeetingList;
