import React from "react";
import { Form, Icon, Input, Item, Label } from "semantic-ui-react";
import { getCurrentSchool } from "../helpers";
import TextareaAutosize from "react-textarea-autosize";
import LookupCode from "../LookupCode";
import { useTranslation } from 'react-i18next';

function FreeSchoolMealsApplicationSummary({
  children,
  includeChildren,
  person,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="mb-4">
        <Label color="teal" ribbon>
          <Icon name="mobile alternate" />
          {t('Details')}
        </Label>
        <Form className="mt-4">
          <Form.Input label={t("First Name")} value={person.forename} />
          <Form.Input label={t("Surname")} value={person.surname} />
          <Form.Input
            label={t("National Insurance/NASS Code")}
            value={person.niNumber}
          />
          <Form.TextArea
            control={TextareaAutosize}
            label={t("Address")}
            value={person.personAddresses
              .filter((personAddress) => personAddress.isCurrent)
              .map((personAddress) => personAddress.address.formatted)}
          />
          {person.personContacts
            .filter((contact) => contact.isCurrent)
            .map((contact) => (
              <Form.Field
                key={`${contact.contactCode}-${contact.contactValue}-${contact.startDate}`}
              >
                <label>
                  <LookupCode codeType={5001} codeValue={contact.contactCode} />
                </label>
                <Input value={contact.contactValue} />
              </Form.Field>
            ))}
        </Form>
      </div>
      <div>
        <Label color="teal" ribbon>
          <Icon name="users" />
          {t('Children')}
        </Label>
        <Item.Group divided>
          {children.map((child) => (
            <Item key={child.pupil.pupilId}>
              <Item.Content>
                <Item.Header as="a">
                  {child.pupil.forenames} {child.pupil.surname}
                </Item.Header>
                <Item.Meta>
                  {getCurrentSchool(child.pupil.edHistory).estabName}
                </Item.Meta>
                <Item.Description>
                  {t('Year Group')} {child.pupil.yearGroup}
                </Item.Description>
                <Item.Extra>
                  <Form.Checkbox
                    label={t("Include")}
                    toggle
                    checked={includeChildren.includes(child.pupil.pupilId)}
                  />
                </Item.Extra>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
      </div>
    </React.Fragment>
  );
}

export default FreeSchoolMealsApplicationSummary;
