import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Button
} from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";

export default function RegistrationConfidentiality() {
  const { t } = useTranslation();

  const { data: statementHeader } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HEADER",
    contentArea: "Registration",
    contentItem: "Statement Header",
    accessLevel: "Public"
  });

  const { data: agreementFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HELP",
    contentArea: "Registration",
    contentItem: "Agreement Footer Text",
    accessLevel: "Public"
  });

  return (
    <Container className="pt-12">
      <Grid centered>
        <Grid.Row>
          <Grid.Column style={{ maxWidth: 650 }}>
            <Header as="h1" attached="top">
              {t(statementHeader?.contentValue || "")}
            </Header>
            <Segment attached="bottom">
              <div
                style={{
                  border: "1px solid #dae1e7",
                  padding: "5px",
                  whiteSpace: "pre-wrap",
                  overflowY: "auto",
                  maxHeight: "450px"
                }}
              >
                {t('Parents Confidentiality Statement')}
              </div>
              <strong>{t(agreementFooter?.contentValue || "")}</strong>
              <div style={{ marginTop: "10px" }}>
                <Button color="red" as={Link} to="/login">
                  <Icon name="remove circle" />
                  {t("Cancel Registration")}
                </Button>
                <Button
                  color="blue"
                  as={Link}
                  to={{ pathname: "/register", state: { agreeInd: "Y" } }}
                  style={{ marginLeft: "5px" }}
                >
                  <Icon name="check" />
                  {t("Agree & Proceed")}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
