import React, { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Message,
  Segment,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import impulse_nexus_wheel from "./assets/impulse_nexus_wheel.png";
import request from "./request";
import { useTranslation } from "react-i18next";
import HelpText from "./components/HelpText";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";
import { toUpper } from "ramda";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function InviteRegistrationScreen() {
  const { t } = useTranslation();

  const { data: contactFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "BIGTEXT",
    contentArea: "Registration",
    contentItem: "Contact Us Help Text Footer",
    accessLevel: "Public",
  });

  const [status, setStatus] = useState(STATUS.INIT);
  const [niNassNumber, setNiNassNumber] = useState("");
  const [personBirthdate, setPersonBirthdate] = useState("");
  const [childBirthdate, setChildBirthdate] = useState("");
  const [postcode, setPostcode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const location = useLocation();
  const history = useHistory();

  const submitRegistration = () => {
    setStatus(STATUS.IN_PROG);
    const data = {
      email: location.state.email,
      personId: location.state.personId,
      activationKey: location.state.activationKey,
      password: password,
      benefitClaimant: location.state.isBenefitClaimant ? "Y" : "N",
      agreeInd: location.state.agreeInd,
      niNassNumber: niNassNumber,
      birthdate: personBirthdate,
      schoolAgedChildBirthdate: childBirthdate,
      postcode: postcode,
    };
    request
      .post("/api/activation/verify", data)
      .then((response) => {
        if (response.data === true) {
          history.push("/login");
          setVerificationError("");
        } else {
          setVerificationError(
            "The details you have entered cannot be verified"
          );
        }
        setStatus(STATUS.DONE);
      })
      .catch((error) => {
        setStatus(STATUS.DONE);
        setVerificationError(error.response.data.error);
      });
    setStatus(STATUS.DONE);
  };

  return (
    <div className=" flex min-h-screen  ">
      <Segment attached={"top"}>
        <div className="flex-grow text-center">
          <Grid centered columns={4}>
            <Grid.Column>
              <div className="flex items-center  p-10 ">
                <img src={impulse_nexus_wheel} alt="" />
              </div>
            </Grid.Column>
          </Grid>

          <div className="text-4xl font-bold mb-6">
            {t("Parents Portal - Registration")}
          </div>
          <Divider />
        </div>

        <Grid centered columns={3}>
          <Grid.Column>
            <Segment padded="very" attached={"top"}>
              <div className="flex-grow text-center">
                <div className="text-1xl mb-6 font-bold ">
                  {t(
                    "To continue your registration, please provide the following information"
                  )}
                </div>
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitRegistration();
                }}
              >
                <Message
                  verificationError
                  hidden={!verificationError}
                  onDismiss={() => setVerificationError("")}
                >
                  {t(verificationError)}
                </Message>
                {location.state.isBenefitClaimant && (
                  <Form.Input
                    required
                    icon={"user circle outline"}
                    iconPosition={"left"}
                    label={t(
                      "The National Insurance or NASS Number registered with us"
                    )}
                    value={niNassNumber}
                    placeholder={t("NI or NASS Number")}
                    onChange={(e) => setNiNassNumber(e.target.value)}
                  />
                )}
                {location.state.isBenefitClaimant && (
                  <Form.Input
                    required
                    type="date"
                    icon={"user circle outline"}
                    iconPosition={"left"}
                    label={t("Your Date of Birth")}
                    value={personBirthdate}
                    placeholder={t("Birthdate")}
                    onChange={(e) => setPersonBirthdate(e.target.value)}
                  />
                )}
                {!location.state.isBenefitClaimant && (
                  <Form.Input
                    required
                    icon={"user circle outline"}
                    iconPosition={"left"}
                    label={t("Postcode at your registered address")}
                    value={postcode}
                    placeholder={t("Postcode")}
                    onChange={(e) => setPostcode(toUpper(e.target.value))}
                  />
                )}
                {!location.state.isBenefitClaimant && (
                  <Form.Input
                    required
                    type="date"
                    label={t(
                      "Date of Birth of a School Aged Child Registered with us"
                    )}
                    value={childBirthdate}
                    placeholder={t("Birthdate")}
                    onChange={(e) => setChildBirthdate(e.target.value)}
                  />
                )}
                <Form.Input
                  required
                  label={
                    <>
                      {t("Please Enter a Password")}{" "}
                      <HelpText
                        contentArea="Registration"
                        contentModule="Request Access"
                        contentType="HELP"
                        contentItem="Password Rules Help Text"
                        accessLevel="Public"
                      />
                    </>
                  }
                  value={password}
                  placeholder={t("Add password...")}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Input
                  required
                  label={t("Confirm Password")}
                  value={confirmPassword}
                  placeholder={t("Re-type password...")}
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    e.target.value && e.target.value !== password
                      ? setPasswordError("Passwords must match")
                      : setPasswordError("");
                  }}
                />
                <Message
                  passwordError
                  hidden={!passwordError}
                  onDismiss={() => setPasswordError("")}
                >
                  {t(passwordError)}
                </Message>
                <Button
                  fluid
                  type={"submit"}
                  positive
                  disabled={status === STATUS.IN_PROG || passwordError}
                  loading={status === STATUS.IN_PROG}
                >
                  {t("Confirm")}
                </Button>
              </Form>
            </Segment>
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: contactFooter?.contentValue,
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
}

export default InviteRegistrationScreen;
