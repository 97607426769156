import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Form, Segment } from "semantic-ui-react";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { lookupToOptions, v, yesNoOptions } from "../../utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const GET_DATA = gql`
  {
    lookupCodes(codeTypes: [5001]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

const CREATE_PERSON_CONTACT = gql`
  mutation CreatePersonContact(
    $contactCode: String!
    $contactValue: String!
    $preferred: String!
    $alternative: String
  ) {
    createPersonContact(
      contactCode: $contactCode
      contactValue: $contactValue
      preferred: $preferred
      alternative: $alternative
    ) {
      personId
    }
  }
`;

const schema = yup.object({
  contactCode: yup.string().required("Required"),
  preferred: yup.string().required("Required"),
  alternative: yup.string().required("Required"),
  contactValue: yup
    .string()
    .required("Required")
    .when("contactCode", {
      is: (value) => ["PHM", "PWK", "PMB"].includes(value),
      then: yup
        .string()
        .test(
          "is-integer",
          "Digits only, no spaces or special characters",
          (value) => /^\d+$/.test(value)
        ),
    })
    .when("contactCode", {
      is: (value) => ["EHM", "EPC", "EWK"].includes(value),
      then: yup.string().email("Please enter a valid email"),
    }),
});

function ContactDetails() {
  const { t } = useTranslation();
  const history = useHistory();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { loading, data } = useQuery(GET_DATA);

  const [createPersonContact, { loading: isCreating }] = useMutation(
    CREATE_PERSON_CONTACT,
    {
      refetchQueries: [
        {
          query: gql`
            {
              portalUser {
                person {
                  personContacts {
                    commsPreferredMethodOrder
                    contactCode
                    contactValue
                    preferred
                    alternative
                    startDate
                    endDate
                  }
                }
              }
            }
          `,
        },
      ],
      onCompleted: () => {
        addToast(t("Contact created"), { appearance: "success" });
        history.push("/profile/contacts");
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Contact not created"), { appearance: "error" });
        });
      },
    }
  );

  const { addToast } = useToasts();

  const onSubmit = ({ contactCode, contactValue, preferred, alternative }) => {
    createPersonContact({
      variables: {
        contactCode,
        contactValue,
        preferred,
        alternative,
      },
    });
  };

  if (loading) return <LoadingMessage />;

  return (
    <Segment>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Dropdown
            required
            name="contactCode"
            label={t("Type")}
            options={lookupToOptions(5001)(v(data, "lookupCodes", []))}
          />
          <Input required name="contactValue" label={t("Value")} />
          <Dropdown
            required
            name="preferred"
            label={t("Preferred")}
            options={yesNoOptions}
          />
          <Dropdown
            required
            name="alternative"
            label={t("Alternative")}
            options={yesNoOptions}
          />
          <Button className={'orange'} as={Link} to="/profile/contacts">
            {t("Cancel")}
          </Button>
          <Button disabled={isCreating} className={'green'}>{t("Save")}</Button>
        </Form>
      </FormProvider>
    </Segment>
  );
}

export default ContactDetails;
