import React from "react";
import { Switch, Link, Route } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import FreeSchoolMealsApplication from "./FreeSchoolMealsApplication";
import { gql } from "apollo-boost";
import LoadingMessage from "../LoadingMessage";
import { v } from "../utils";
import { useTranslation } from 'react-i18next';

const GET_DATA = gql`
  {
    portalUser {
      person {
        activeBenefitClaim(claimType: "FSM") {
          id
          inProgress
        }
      }
    }
  }
`;

function FreeSchoolMeals() {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA);

  if (loading) return <LoadingMessage />;

  const exists = () => {
    return v(data, ["portalUser", "person", "activeBenefitClaim", "id"], 0) > 0;
  };

  const inProgress = () => {
    return v(
      data,
      ["portalUser", "person", "activeBenefitClaim", "inProgress"],
      false
    );
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path="/free-school-meals/apply">
          <FreeSchoolMealsApplication />
        </Route>
        <Route>
          <div className="border rounded bg-white">
            <div className="text-3xl p-4 tracking-tight leading-10 font-black">
              {t('Free School Meals')}
            </div>
            <div className="p-4">
              <div className="leading-5 mb-6">
                {t(`All children starting school in the reception class, Year 1, or
                Year 2 will get a free meal at school regardless of income. This
                includes academies, free schools, pupil referral units and
                alternative provision as well as maintained schools. You do not
                need to apply for free school meals if your child meets the
                above criteria, however if you are on a low income you should
                still apply so that your child’s school can claim the Pupil
                Premium. This is extra money paid direct to schools for each
                child who is eligible for free school meals due to the household
                being on a low income. It is used to support your child’s
                education.`)}
              </div>
              <div className="leading-5 mb-6">
                {t(`Parents of children attending school nursery, junior classes in
                Years 3 to 6, or at secondary school, will have to pay for
                school meals. To qualify for free meals, you must meet the
                eligibility criteria listed below. If your child qualifies for
                free school meals you can save up to £10 a week or £390 a year.`)}
              </div>
              <div className="leading-5">
                {t(`It doesn’t matter where you live as long as your child goes to
                school in Hackney. To qualify, your child must be in school and
                you must be getting one of the following benefits`)}:
              </div>
            </div>
            <div className="p-4">
              <Button
                as={Link}
                className={'blue'}
                to="/free-school-meals/apply"
                disabled={exists() && !inProgress()}
              >
                {exists()
                  ? inProgress()
                    ? t("Resume")
                    : t("Application Submitted")
                  : t("Apply Now")}
              </Button>
            </div>
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default FreeSchoolMeals;
