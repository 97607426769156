import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Message, Icon, Divider } from "semantic-ui-react";
import request from "./request";
import LanguageList from "./LanguageList";

function ActivationScreen() {
  const { activationKey } = useParams();
  const [isActivating, setIsActivating] = useState(false);
  const [activatingError, setActivatingError] = useState(false);
  const [activatingSuccess, setActivatingSuccess] = useState(false);

  useEffect(() => {
    const activate = async () => {
      setIsActivating(true);

      try {
        await request.get(`/api/activate/${activationKey}`);
        setActivatingSuccess(true);
      } catch (e) {
        console.error(e);
        setActivatingError(true);
      } finally {
        setIsActivating(false);
      }
    };

    activate();
  }, [activationKey]);

  if (isActivating) {
    return (
      <Container>
        <Message icon info>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Activating Account</Message.Header>
            Please wait while we activate your account.
          </Message.Content>
        </Message>
        <Divider />
        <LanguageList />
      </Container>
    );
  }

  if (activatingError) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon error>
          <Icon name="warning" />
          <Message.Content>
            <Message.Header>Activation Failed</Message.Header>
            We failed to activate your account.
          </Message.Content>
        </Message>
        <Divider />
        <LanguageList />
      </Container>
    );
  }

  if (activatingSuccess) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <Message.Header>Account Activated</Message.Header>
            Please click here to go back to the login screen.
          </Message.Content>
        </Message>
        <Divider />
        <LanguageList />
      </Container>
    );
  }

  return null;
}

export default ActivationScreen;
