import React from "react";
import { Button, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useFieldArray, useFormContext } from "react-hook-form";
import ProfessionalInvolvedDetails from "./ProfessionalInvolvedDetails";
import { useTranslation } from 'react-i18next';
import HelpText from "../components/HelpText";

function ProfessionalsInvolved({ lookupCodes }) {
  const { t } = useTranslation();
  const { control, errors, setValue, register } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "professionals",
  });

  return (
    <React.Fragment>
      <Segment attached="top">
        <h3>
            {t('Professionals Involved')}
            <HelpText
                contentArea="Referral Professionals"
                contentModule="Referrals"
                contentType="HELP"
                contentItem="Professionals Involved"
                accessLevel="Private"
            />
        </h3>
      </Segment>
      <Segment attached>
        {!fields.length && (
          <Message info>
            <Message.Header>{t('Professionals Involved')}</Message.Header>
            <Message.Content>
                {t('No professionals involved. Click the')} <b>{t('Add Professional')}</b>{" "}
                {t('button to add a new professional.')}
            </Message.Content>
          </Message>
        )}
        {fields.map((field, index) => (
          <ProfessionalInvolvedDetails
            key={field.id}
            field={field}
            index={index}
            control={control}
            errors={errors}
            setValue={setValue}
            register={register}
            remove={remove}
            lookupCodes={lookupCodes}
          />
        ))}
      </Segment>
      <Segment attached>
        <Button onClick={append} style={{backgroundColor:'#9D3293', color:'#FFF'}}>{t('Add Professional')}</Button>
      </Segment>
      <Segment attached="bottom">
        <Button as={Link} to="/referrals/details" className={"orange"}>
            {t('Back')}
        </Button>
        <Button as={Link} to="/referrals/notes" style={{backgroundColor:'#0054A4', color:'#FFF'}}>
            {t('Next')}
        </Button>
      </Segment>
    </React.Fragment>
  );
}

export default ProfessionalsInvolved;
