import React from "react";
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

function FreeSchoolMealsApplicationDeclaration({
  agreedDeclaration,
  setAgreedDeclaration,
  agreedECS,
  setAgreedECS,
  isInProgress,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Message info>
        <Message.Content>
          <p>
            {t(`I confirm that information I have included in this application is
            correct and complete.`)}
          </p>
          <p>{t('Use of Data for the processing of this claim.')}</p>
          <p>{t('Evidence included is accurate.')}</p>
          <p>{t('Please agree.')}</p>
        </Message.Content>
      </Message>
      <Form>
        <Form.Checkbox
          label={t("Agree Declaration")}
          checked={agreedDeclaration}
          onClick={(_, { checked }) => {
            if (checked) {
              setAgreedDeclaration(true);
            } else {
              setAgreedDeclaration(false);
            }
          }}
        />
        <Form.Checkbox
          label={t("Agree to Eligibility Checking Service (ECS)")}
          checked={agreedECS}
          onClick={(_, { checked }) => {
            if (checked) {
              setAgreedECS(true);
            } else {
              setAgreedECS(false);
            }
          }}
        />
      </Form>
    </>
  );
}

export default FreeSchoolMealsApplicationDeclaration;
