import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import impulse_nexus_wheel from "./assets/impulse_nexus_wheel.png";
import request from "./request";
import { useTranslation } from "react-i18next";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function CodeActivationScreen() {
  const { t } = useTranslation();

  const { data: contactFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "BIGTEXT",
    contentArea: "Registration",
    contentItem: "Contact Us Help Text Footer",
    accessLevel: "Public",
  });

  const { data: statementHeader } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HEADER",
    contentArea: "Registration",
    contentItem: "Statement Header",
    accessLevel: "Public",
  });

  const { data: agreementFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HELP",
    contentArea: "Registration",
    contentItem: "Agreement Footer Text",
    accessLevel: "Public",
  });

  const [status, setStatus] = useState(STATUS.INIT);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isBenefitClaimant, setIsBenefitClaimant] = useState("");
  const [personId, setPersonId] = useState("");
  const [activationKey, setActivationKey] = useState("");
  const [displayStatement, setDisplayStatement] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const submitActivationCode = () => {
    setStatus(STATUS.IN_PROG);
    const data = {
      email: email,
      activationKey: activationKey,
    };
    request
      .post("/api/activation/code", data)
      .then((response) => {
        if(displayStatement) {
          setIsCodeValid(true);
          setStatus(STATUS.DONE);
          setIsBenefitClaimant(response.data.benefitClaimant);
          setPersonId(response.data.personId);
          setError("");
        }else{
          history.push({pathname: "/invite-registration",
            state: {
              agreeInd: "N",
              email: email,
              isBenefitClaimant: response.data.benefitClaimant,
              personId: response.data.personId,
              activationKey: activationKey,
            },});
        }
      })
      .catch(() => {
        setStatus(STATUS.DONE);
        setError("Incorrect Activation Code and/or Email Address .");
      });
    setStatus(STATUS.DONE);
  };

  useEffect(() => {
    request
      .get("/api/info/PAR_REG_CHK")
      .then(({ data: { active } }) => setDisplayStatement(active === "Y") || "")
      .catch(console.log);
  }, []);

  if (displayStatement && isCodeValid) {
    return (
      <Container className="pt-12">
        <Grid centered>
          <Grid.Row>
            <Grid.Column style={{ maxWidth: 650 }}>
              <Header as="h1" attached="top">
                {t(statementHeader?.contentValue || "")}
              </Header>
              <Segment attached="bottom">
                <div
                  style={{
                    border: "1px solid #dae1e7",
                    padding: "5px",
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    maxHeight: "450px",
                  }}
                >
                  {t("Parents Confidentiality Statement")}
                </div>
                <strong>{t(agreementFooter?.contentValue || "")}</strong>
                <div style={{ marginTop: "10px" }}>
                  <Button color="red" as={Link} to="/login">
                    <Icon name="remove circle" />
                    {t("Cancel Registration")}
                  </Button>
                  <Button
                    color="blue"
                    as={Link}
                    to={{
                      pathname: "/invite-registration",
                      state: {
                        agreeInd: "Y",
                        email: email,
                        isBenefitClaimant: isBenefitClaimant,
                        personId: personId,
                        activationKey: activationKey,
                      },
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    <Icon name="check" />
                    {t("Agree & Proceed")}
                  </Button>
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  return (
    <div className=" flex min-h-screen  ">
      <Segment attached={"top"}>
        <div className="flex-grow text-center">
          <Grid centered columns={4}>
            <Grid.Column>
              <div className="flex items-center  p-10 ">
                <img src={impulse_nexus_wheel} alt="" />
              </div>
            </Grid.Column>
          </Grid>

          <div className="text-4xl font-bold mb-6">
            {t("Parents Portal - Registration - Activation Code")}
          </div>
          <Divider />
        </div>

        <Grid centered columns={3}>
          <Grid.Column>
            <Segment padded="very" attached={"top"}>
              <div className="flex-grow text-center">
                <div className="text-1xl font-bold ">{t("Welcome!")}</div>
                <div className="text-1xl mb-6 font-bold ">
                  {t(
                    "Please provide the activation code supplied in your email invite."
                  )}
                </div>
              </div>
              <Message error hidden={!error} onDismiss={() => setError("")}>
                {t(error)}
              </Message>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitActivationCode();
                }}
              >
                <Form.Input
                  required
                  label={t("Activation Code")}
                  icon={"calculator"}
                  iconPosition={"left"}
                  value={activationKey}
                  placeholder={t("Please enter your activation code...")}
                  onChange={(e) => setActivationKey(e.target.value)}
                ></Form.Input>
                <Form.Input
                  required
                  icon={"user circle outline"}
                  iconPosition={"left"}
                  label={t("Email")}
                  value={email}
                  placeholder={t("Please enter your email...")}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  fluid
                  type={"submit"}
                  positive
                  disabled={
                    status === STATUS.IN_PROG || !email || !activationKey
                  }
                  loading={status === STATUS.IN_PROG}
                >
                  {t("Confirm")}
                </Button>
              </Form>
            </Segment>
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: contactFooter?.contentValue,
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
}

export default CodeActivationScreen;
