import React from "react";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Menu } from "semantic-ui-react";
import EducationHistory from "./EducationHistory";
import ChildOverview from "./ChildOverview";
import Plans from "./Plans";
import LoadingMessage from "../../LoadingMessage";
import ContributionsScreen from "./ContributionsScreen";
import MeetingsScreen from "./MeetingsScreen";
import CommunicationsScreen from "./CommunicationsScreen";
import GenericErrorMessage from "../../GenericErrorMessage";
import { useTranslation } from "react-i18next";
import HelpText from "../../components/HelpText";
import Dropdown from "../../Dropdown";
import CaseNotes from "./CaseNotes";

const PUPIL = gql`
  query Pupil($pupilId: Int!) {
    pupil(pupilId: $pupilId) {
      forenames
      surname
    }
  }
`;

function Child() {
  const { pupilId } = useParams();
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(PUPIL, {
    variables: { pupilId },
  });

  if (loading) return <LoadingMessage />;

  if (!!error) return <GenericErrorMessage />;

  return (
    <React.Fragment>
      <div className="flex flex-row mb-4 border-b">
        <h2 className="text-2xl tracking-tight leading-10 text-gray-800 font-bold pb-2">
          {data.pupil.forenames} {data.pupil.surname}{" "}
          <HelpText
              contentArea="Child Record"
              contentModule="Children"
              contentType="HELP"
              contentItem="Overview"
              accessLevel="Private"
          />
        </h2>
      </div>
      <Menu pointing secondary>
        <Menu.Item as={NavLink} to={`/children/${pupilId}`} exact>
          {t("Overview")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/education-history`}>
          {t("Education History")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/plans`}>
          {t("Plans")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/contributions`}>
          {t("Contributions")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/meetings`}>
          {t("Meetings")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/communications`}>
          {t("Communications")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/case-notes`}>
          {t("Case Notes")}
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/children/:pupilId/education-history">
          <EducationHistory />
        </Route>
        <Route path="/children/:pupilId/plans">
          <Plans />
        </Route>
        <Route path="/children/:pupilId/contributions">
          <ContributionsScreen Dropdowns={Dropdown}/>
        </Route>
        <Route path="/children/:pupilId/meetings">
          <MeetingsScreen />
        </Route>
        <Route path="/children/:pupilId/communications">
          <CommunicationsScreen />
        </Route>
        <Route path="/children/:pupilId/case-notes">
          <CaseNotes Dropdowns={Dropdown} />
        </Route>
        <Route path="/children/:pupilId" exact>
          <ChildOverview />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Child;
