import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Icon,
  Message
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import a from "./assets/fatherhood.svg";
import request from "./request";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./Input";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguageList from "./LanguageList";
import HelpText from "./components/HelpText";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2
};

function ForgotPassword() {
  const { t } = useTranslation();

  const validations = yup.object({
    email: yup
      .string()
      .required(t("Required"))
      .email(t("Please enter a valid email"))
  });

  const form = useForm({
    resolver: yupResolver(validations)
  });
  const [status, setStatus] = useState(STATUS.INIT);

  const reset = ({ email }) => {
    setStatus(STATUS.IN_PROG);
    request(`/api/reset/${email}`)
      .then(() => {
        setStatus(STATUS.DONE);
      })
      .catch(() => {
        setStatus(STATUS.DONE);
      });
  };

  if (status === STATUS.DONE) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <Message.Header>{t("Email Sent")}</Message.Header>
            {t(
              "Please check your email with instructions on how to complete your password reset."
            )}
          </Message.Content>
        </Message>
        <Divider />
        <LanguageList />
      </Container>
    );
  }

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">{t("Parents Portal")}</div>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(reset)}>
              <Input
                label={
                  <>
                    {t("Email")}{" "}
                    <HelpText
                      contentArea="Forgot Password"
                      contentModule="Login"
                      contentType="HELP"
                      contentItem="Forgot Password"
                      accessLevel="Public"
                    />
                  </>
                }
                name="email"
                placeholder="Please enter your email..."
              />
              <Button
                positive
                disabled={status === STATUS.IN_PROG}
                loading={status === STATUS.IN_PROG}
              >
                {t("Reset Password")}
              </Button>
            </Form>
          </FormProvider>
          <div className="mt-6">
            <Link className="underline" to="/login">
              {t("Back to the login screen")}
            </Link>
          </div>
          <div className="mt-6">
            <Divider />
            <LanguageList />
          </div>
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={a} alt="" />
      </div>
    </div>
  );
}

export default ForgotPassword;
