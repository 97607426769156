import React from "react";
import { Controller } from "react-hook-form";
import { FormField, Select } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import { v } from "./utils";

function Dropdown({ name, label, required = false, options = [], cb = () => {}, path = [] }) {
  const { control, errors } = useFormContext();

  const hasErrors = !!v(errors, !!path.length ? path : [name], "");

  const errorMessage = v(
    errors,
    !!path.length ? path.concat(["message"]) : [name, "message"],
    ""
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FormField error={hasErrors} required={required}>
          <label>{label}</label>
          <Select
            options={[{ value: "", text: "Clear Value" }].concat(options)}
            search
            selection
            onChange={(_, { value }) => {
              onChange(value);
              cb(value);
            }}
            value={value}
          />
          {hasErrors && (
            <span className="text-red-700 text-xs">{errorMessage}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default Dropdown;
