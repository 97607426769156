import React, { createContext, useEffect, useState } from "react";
import request from "./request";
import i18n, { DEFAULT_NS } from "./translation/i18n";

export const LanguageContext = createContext({
  language: "",
  setLanguage: () => {},
  languages: [],
});

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem("locale"));
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    request
      .get("/api/translation-language")
      .then(({ data }) => setLanguages(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    request
      .get(`/api/translation-text/${language}`)
      .then(({ data }) => {
        const translations = data.reduce((acc, cur) => {
          acc[cur.code] = cur.translation;
          return acc;
        }, {});
        i18n.addResourceBundle(language, DEFAULT_NS, translations);
        i18n.changeLanguage(language);
        localStorage.setItem("locale", language);
      })
      .catch(console.error);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};
