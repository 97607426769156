import { Container, Dropdown, Image, Menu, Popup } from "semantic-ui-react";
import { v } from "../utils";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import i18n, { DEFAULT_NS } from "../translation/i18n";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from 'react-idle-timer'

const GET_DATA = gql`
  {
    translationLanguages {
      id
      locale
      description
    }
    portalUser {
      langKey
    }
    translationTexts {
      locale
      code
      translation
    }
    timeout
  }
`;

function TopMenu() {
  const { t } = useTranslation();
  const [timeout, setTimeout] = useState(-1);
  const history = useHistory();

  const handleOnIdle = () => {
    sessionStorage.removeItem("token")
    history.push("/login?idle=true");
  }

  useEffect(() => {
    setTimeout(timeout);
  }, [timeout]);

  const { } = useIdleTimer({
    timeout: timeout,
    onIdle:  timeout > 0 ? handleOnIdle: () => {},
    debounce: 500,
    promptBeforeIdle: timeout - 1,
  })

  const [updateLocale] = useMutation(
    gql`
      mutation($langKey: String!) {
        updateUserLanguage(langKey: $langKey) {
          langKey
          translationTexts {
            locale
            code
            translation
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const translations = v(
          data,
          "updateUserLanguage.translationTexts",
          []
        ).reduce((acc, cur) => {
          acc[cur.code] = cur.translation;
          return acc;
        }, {});
        i18n.addResourceBundle(
          data.updateUserLanguage.langKey,
          DEFAULT_NS,
          translations
        );
        i18n.changeLanguage(data.updateUserLanguage.langKey);
        localStorage.setItem("locale", data.updateUserLanguage.langKey);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: GET_DATA,
        },
      ],
    }
  );

  const { data } = useQuery(GET_DATA, {
    onCompleted: (data) => {
      const translations = v(data, ["translationTexts"], []).reduce(
        (acc, cur) => {
          acc[cur.code] = cur.translation;
          return acc;
        },
        {}
      );
      i18n.addResourceBundle(
        v(data, ["portalUser", "langKey"], ""),
        DEFAULT_NS,
        translations
      );
      i18n.changeLanguage(data.portalUser.langKey);
      localStorage.setItem("locale", data.portalUser.langKey);
      setTimeout(data.timeout);
    },
  });

  return (
    <Container fluid>
      <Menu attached inverted>
        <Menu.Item>
          <Image
            src={"/content/images/impulse_logo_small.png"}
            alt="Impulse Logo"
          />
        </Menu.Item>
        <Menu.Menu position="right">
          <Popup
            trigger={
              <Dropdown icon="world" item>
                <Dropdown.Menu>
                  {v(data, ["translationLanguages"], []).map((language) => (
                    <Dropdown.Item
                      key={language.locale}
                      text={t(language.description)}
                      onClick={() =>
                        updateLocale({
                          variables: { langKey: language.locale },
                        })
                      }
                      icon={
                        data.portalUser.langKey === language.locale
                          ? "check"
                          : undefined
                      }
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            }
            content={t("Language")}
          />
          <Menu.Item
            as={Link}
            to="/login"
            onClick={() => sessionStorage.removeItem("token")}
          >
            {t("Logout")}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  );
}

export default TopMenu;
