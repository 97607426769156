import React from "react";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import NewCommunicationScreen from "./NewCommunicationScreen";
import SentCommunicationDetailsScreen from "./SentCommunicationDetailsScreen";
import { Menu } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { gql } from "apollo-boost";
import CommunicationsReceived from "./CommunicationsReceived";
import { v } from "../../utils";
import CommunicationsSent from "./CommunicationsSent";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Dropdown";

const GET_COMMUNICATIONS = gql`
  query {
    lookupCodes(codeTypes: [6067, 6068]) {
      codeType
      codeValue
      codeDescription
    }
    standardLetters {
      letterCode
      letterDescription
    }
  }
`;

function CommunicationsScreen() {
  const { t } = useTranslation();
  const { pupilId } = useParams();

  const { loading, data } = useQuery(GET_COMMUNICATIONS);

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <Menu pointing secondary>
        <Menu.Item
          as={NavLink}
          to={`/children/${pupilId}/communications`}
          exact
        >
          {t("Received")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/children/${pupilId}/communications/sent`}>
          {t("Sent")}
        </Menu.Item>
        <Menu.Item
          className={"purple"}
          as={NavLink}
          to={`/children/${pupilId}/communications/new`}
        >
          {t("New")}
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/children/:pupilId/communications" exact>
          <CommunicationsReceived
            pupilId={pupilId}
            lookupCodes={v(data, "lookupCodes", [])}
            standardLetters={v(data, "standardLetters", [])}
            Dropdowns={Dropdown}
          />
        </Route>
        <Route path="/children/:pupilId/communications/sent" exact>
          <CommunicationsSent
            lookupCodes={v(data, "lookupCodes", [])}
            pupilId={pupilId}
            Dropdowns={Dropdown}
          />
        </Route>
        <Route path="/children/:pupilId/communications/sent/:communicationId">
          <SentCommunicationDetailsScreen />
        </Route>
        <Route path="/children/:pupilId/communications/new">
          <NewCommunicationScreen lookupCodes={v(data, "lookupCodes", [])} />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default CommunicationsScreen;
