import React from "react";
import { Form, Input } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import LookupCode from "../LookupCode";
import { useTranslation } from 'react-i18next';

function FreeSchoolMealsApplicationDetails({ person }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Form>
        <Form.Input label={t("First Name")} value={person.forename} />
        <Form.Input label={t("Surname")} value={person.surname} />
        <Form.Input
          label={t("National Insurance/NASS Code")}
          value={person.niNumber}
        />
        <Form.TextArea
          control={TextareaAutosize}
          label={t("Address")}
          value={person.personAddresses
            .filter((personAddress) => personAddress.isCurrent)
            .map((personAddress) => personAddress.address.formatted)}
        />
        {person.personContacts
          .filter((contact) => contact.isCurrent)
          .map((contact) => (
            <Form.Field
              key={`${contact.contactCode}-${contact.contactValue}-${contact.startDate}`}
            >
              <label>
                <LookupCode codeType={5001} codeValue={contact.contactCode} />
              </label>
              <Input
                label={
                  contact.isMain
                    ? { icon: "asterisk", color: "teal" }
                    : undefined
                }
                labelPosition={contact.isMain ? "left corner" : undefined}
                value={contact.contactValue}
              />
            </Form.Field>
          ))}
      </Form>
    </React.Fragment>
  );
}

export default FreeSchoolMealsApplicationDetails;
