import React, { useState } from "react";
import {Button, Modal,Icon} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function DeleteConfirmationModal({ children, onDelete }) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        size="mini"
        open={open}
        onClose={() => setOpen(false)}
        trigger={<span onClick={() => setOpen(true)}>{children}</span>}
      >
        <div className="bg-white p-8 text-xl font-black tracking-wide">
            {t("You're about to delete a record")}
        </div>
        <div className="flex justify-end bg-gray-100 p-5">
        <Button className={'orange'} onClick={() => setOpen(false)}>
            <Icon name="remove circle" />
            {t("Cancel")}
        </Button>
          <div
            className="p-2 rounded bg-red-500 text-white hover:bg-red-600 cursor-pointer shadow"
            style={{paddingTop:'11px'}}
            onClick={() => {
              onDelete();
              setOpen(false);
            }}
          >
          <Icon name="trash alternate" />
          {t("Delete")}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteConfirmationModal;
