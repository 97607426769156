import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Pagination,
  Segment,
} from "semantic-ui-react";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  formatSize,
  lookupFormatter,
  v,
} from "../../utils";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import { useTranslation } from "react-i18next";

const GET_DATA = gql`
  query communication($id: Int!, $page: Int!, $size: Int!) {
    communication(id: $id) {
      id
      commDate
      summary
      recipientName
      commInType
      senderServiceDefinition {
        person {
          fullName
        }
      }
      communicationDocuments(page: $page, size: $size) {
        number
        totalPages
        content {
          ... on CommunicationDocument {
            id
            files {
              id
              docSize
              filename
              mimeType
              base64
              application
            }
          }
        }
      }
    }
    lookupCodes(codeTypes: [6068]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

function SentCommunicationDetailsScreen() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { communicationId, pupilId } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const { loading, data, fetchMore } = useQuery(GET_DATA, {
    variables: { id: communicationId, page: 0, size: pageSize },
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        addToast(t("Unable to fetch communications"), { appearance: "error" });
      });
    },
  });

  if (loading) return <LoadingMessage />;

  return (
    <Segment>
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Form.Input
                label={t("Date")}
                value={data.communication.commDate}
                type="date"
                readOnly
              />
              <Form.Input
                label={t("Communication")}
                value={lookupFormatter(
                  data.lookupCodes,
                  data.communication.commInType,
                  6068
                )}
                readOnly
              />
              <Form.Input
                label={t("Recipient")}
                value={
                  data.communication.recipientName
                }
                readOnly
              />
              <Button className={'orange'} as={Link} to={`/children/${pupilId}/communications/sent`}>
                {t("Close")}
              </Button>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.TextArea
                label={t("Summary")}
                value={
                  data.communication.summary
                    ? data.communication.summary.replace(/<\/?[^>]+(>|$)/g, "")
                    : ""
                }
                readOnly
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
            <Table.HeaderCell>{t("File")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {v(data, "communication.communicationDocuments.content", []).map(
            (document) => (
              <Table.Row key={document.id}>
                <Table.Cell collapsing>{document.files.id}</Table.Cell>
                <Table.Cell collapsing>{document.files.filename}</Table.Cell>
                <Table.Cell collapsing>{document.files.application}</Table.Cell>
                <Table.Cell>{formatSize(document.files.docSize)}</Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    onClick={() => {
                      createAndDownloadBlobFile(
                        base64ToArrayBuffer(document.files.base64),
                        document.files.filename,
                        document.files.mimeType
                      );
                    }}
                    style={{backgroundColor: '#00AEEF', color:'#fff'}}
                    className={'light-blue'}
                  >
                    {t("Download")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.communication?.communicationDocuments.number + 1}
              siblingRange={1}
              totalPages={data.communication?.communicationDocuments.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const { communication: prevCommunication } = prev;
                    const communication = Object.assign({}, prevCommunication, {
                      communicationDocuments:
                        fetchMoreResult.communication.communicationDocuments,
                    });
                    return Object.assign({}, prev, { communication });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export default SentCommunicationDetailsScreen;
