import React, { Component } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Form, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSegment = styled(Segment)`
  height: ${props => props.height ? props.height : 'auto'};
  margin: 0rem;
  padding: 0rem !important;
`;

export class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      dirty: false,
    };

    this.onEditorChange = this.onEditorChange.bind(this);
    this.getFormattedValue = this.getFormattedValue.bind(this);
  }

  onEditorChange(editorState) {
      this.setState({ editorState: editorState, dirty: true });
    this.props.onChange({
      target: { value: draftToHtml(convertToRaw(editorState.getCurrentContent())), name: this.props.name },
    });
  }

  getFormattedValue() {
    const contentBlock = htmlToDraft(!!this.props.value ? this.props.value : '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  render() {
    const containerStyle = this.props.containerStyle || {};
    return (
      <Form.Field>
        {this.props.label && <label>{this.props.label}</label>}
        <StyledSegment height={this.props.segmentHeight} style={containerStyle} className={'rich-text-editor-container'}>
          <div style={{ maxHeight: '350px',minHeight: '300px', overflowY: 'scroll', padding: '10px' }}>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'remove',
                  'history',
                ],
              }}
              spellCheck
              editorState={this.state.dirty ? this.state.editorState : this.getFormattedValue()}
              onEditorStateChange={this.onEditorChange}
              readOnly={this.props.readOnly}
            />
          </div>
        </StyledSegment>
      </Form.Field>
    );
  }
}

export default RichTextEditor;
