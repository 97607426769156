import React from "react";
import { Route } from "react-router-dom";
import ContactList from "./ContactList";
import ContactDetails from "./ContactDetails";

function ContactDetailsScreen() {
  return (
    <React.Fragment>
      <Route>
        <ContactList />
      </Route>
      <Route path="/profile/contacts/new">
        <ContactDetails />
      </Route>
    </React.Fragment>
  );
}

export default ContactDetailsScreen;
