function dateFormatter(date) {
  if (!date) return "";

  try {
    return new Date(date).toLocaleDateString("en-GB");
  } catch (e) {
    console.error(e);
    return "";
  }
}

export default dateFormatter;
