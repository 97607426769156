import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link, Route, Switch } from "react-router-dom";
import LoadingMessage from "../LoadingMessage";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import Child from "./child/Child";
import { useTranslation } from "react-i18next";
import dateFormatter from "../DateFormatter";
import HelpText from "../components/HelpText";
import Input from "../Input";
import { FormProvider, useForm } from "react-hook-form";
import { mergeDeepRight } from "ramda";
import { lookupToOptions, v } from "../utils";

const GET_CHILDREN = gql`
  query GetChildren(
    $page: Int!
    $size: Int!
    $query: String!
    $birthdate: LocalDate
  ) {
    portalUser {
      person {
        forename
        surname
        pupilGuardians(
          page: $page
          size: $size
          query: $query
          birthdate: $birthdate
        ) {
          number
          totalPages
          content {
            ... on PupilGuardian {
              pupil {
                pupilId
                forenames
                surname
                birthdate
                yearGroup
              }
            }
          }
        }
      }
    }
  }
`;

function Children() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const searchForm = useForm();
  const { loading, data, fetchMore } = useQuery(GET_CHILDREN, {
    variables: {
      page: 0,
      size: pageSize,
      query: "",
      birthdate: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const submitSearch = ({ query, birthday }) => {
    fetchMore({
      variables: {
        query: query,
        birthdate: birthday ? birthday : null,
        size: pageSize,
        page: 0,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              pupilGuardians: fetchMoreResult.portalUser.person.pupilGuardians,
            },
          },
        });
      },
    }).then(r =>{});
  };

  if (loading) return <LoadingMessage />;

  if (v(data, "portalUser.person.pupilGuardians.content", []).length <= 0)
    return (
      <div>
        {" "}
        <FormProvider {...searchForm}>
          <Form
            onSubmit={searchForm.handleSubmit(submitSearch)}
            style={{ marginBottom: 14 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input name="query" label={t("Name")} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input label={t("Birthdate")} name={"birthday"} type="date" />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type="submit"
                        onClick={() => {
                          searchForm.reset({
                            query: "",
                            birthday: "",
                          });
                        }}
                      >
                        <Icon name="times circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <React.Fragment>
      <Switch>
        <Route path="/children" exact>
          <div className="text-3xl tracking-tight leading-10 font-bold pb-2 border-b">
            {t("Children")}
            <HelpText
              contentArea="Children List"
              contentModule="Children"
              contentType="HELP"
              contentItem="Children List"
              accessLevel="Private"
            />
          </div>
          <FormProvider {...searchForm}>
            <Form
              onSubmit={searchForm.handleSubmit(submitSearch)}
              style={{ marginBottom: 14 }}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Input name="query" label={t("Name")} />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Input
                      label={t("Birthdate")}
                      name={"birthday"}
                      type="date"
                    />
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "5" }}>
                    <br />
                    <Button icon color={"green"} type={"submit"}>
                      <Icon name="search" />
                    </Button>
                    <Popup
                      trigger={
                        <Button
                          icon
                          color={"red"}
                          type="submit"
                          onClick={() => {
                            searchForm.reset({
                              query: "",
                              birthday: "",
                            });
                          }}
                        >
                          <Icon name="times circle" />
                        </Button>
                      }
                      content={t("Clear")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </FormProvider>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Forenames")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Surname")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Date of Birth")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Year group")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.portalUser?.person.pupilGuardians.content.map(
                (pupilGuardian) => (
                  <Table.Row key={pupilGuardian.pupil.pupilId}>
                    <Table.Cell>{pupilGuardian.pupil.forenames}</Table.Cell>
                    <Table.Cell>{pupilGuardian.pupil.surname}</Table.Cell>
                    <Table.Cell>
                      {dateFormatter(pupilGuardian.pupil.birthdate)}
                    </Table.Cell>
                    <Table.Cell>{pupilGuardian.pupil.yearGroup}</Table.Cell>
                    <Table.Cell collapsing>
                      <Button
                        style={{backgroundColor:'#0054A4', color:'#FFF'}}
                        className={"blue"}
                        as={Link}
                        to={`/children/${pupilGuardian.pupil.pupilId}`}
                      >
                        {t("View")}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <br />
                <br />
                <Pagination
                  boundaryRange={1}
                  activePage={data.portalUser?.person.pupilGuardians.number + 1}
                  siblingRange={1}
                  totalPages={data.portalUser?.person.pupilGuardians.totalPages}
                  onPageChange={(e, { activePage }) =>
                    fetchMore({
                      variables: {
                        page: activePage - 1,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        return mergeDeepRight(prev, {
                          portalUser: {
                            person: {
                              pupilGuardians:
                                fetchMoreResult.portalUser.person
                                  .pupilGuardians,
                            },
                          },
                        });
                      },
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column width={2} style={{ textAlign: "center" }}>
                <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                  {t("Record per Page")}
                </label>
                <Segment style={{ padding: "10px" }}>
                  <Dropdown
                    value={pageSize}
                    name="pageSize"
                    options={[
                      { value: 10, text: "10" },
                      { value: 25, text: "25" },
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                    ]}
                    onChange={(_, { value }) => setPageSize(value)}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Route>
        <Route path="/children/:pupilId">
          <Child />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Children;
