import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import Input from "../Input";
import TextArea from "../TextArea";
import { Link } from "react-router-dom";
import FileInput from "../FileInput";
import { useTranslation } from 'react-i18next';
import HelpText from "../components/HelpText";

function Notes() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Segment attached="top">
        <h3>
            {t('Notes')}
            <HelpText
                contentArea="Referral Notes"
                contentModule="Referrals"
                contentType="HELP"
                contentItem="Referral Notes"
                accessLevel="Private"
            />
        </h3>
      </Segment>
      <Segment attached>
        <Form>
          <Input name="noteTitle" label={t("Title")} />
          <TextArea name="noteHtml" label={t("Notes/Comments")} />
          <FileInput name="files" label="File" />
        </Form>
      </Segment>
      <Segment attached="bottom">
        <Button as={Link} to="/referrals/professionals" style={{backgroundColor:'#FF9900', color:'#FFF'}}>
            {t('Back')}
        </Button>
        <Button as={Link} to="/referrals/consent" style={{backgroundColor:'#0054A4', color:'#FFF'}}>
            {t('Next')}
        </Button>
      </Segment>
    </React.Fragment>
  );
}

export default Notes;
