function YesNoFormatter({ value }) {
  switch (value) {
    case "Y":
      return "Yes";
    case "N":
      return "No";
    default:
      return value;
  }
}

export default YesNoFormatter;
