import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { Button, Form, Grid, Icon, Menu, Message, Segment, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  lookupFormatter,
  v,
} from "../../utils";
import LookupCode from "../../LookupCode";

const GET_MEETING = gql`
  query PanelSubject($subjectId: Int!) {
    panelSubject(subjectId: $subjectId) {
      panel {
        panelDate
        panelType
        title
        startTime
        duration
        locationType
        virtualUrl
        locationId
        virtualText
        address {
          formatted
        }
      }
      panelSubjectFamily {
        invited
      }
    }
    panelSubjectContributors(subjectId: $subjectId) {
      panelId
    }
    contributionListDTO(subjectId: $subjectId) {
      forename
      surname
      contributionCount
      personType
      serviceId
    }
    lookupCodes(codeTypes: [6145, 5181, 111, 6050]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

const GET_CONTRIBUTIONS = gql`
  query Contributions($subjectId: Int!, $authorServiceId: Int!) {
    contributions(subjectId: $subjectId, authorServiceId: $authorServiceId) {
      contributionType
      contributionText
      contributionDocuments {
        files {
          id
          filename
          docSize
          base64
          application
        }
      }
    }
  }
`;

function MeetingDetails() {
  const { subjectId, pupilId } = useParams();

  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_MEETING, {
    variables: { subjectId },
  });

  const [
    getContribution,
    { data: conData },
  ] = useLazyQuery(GET_CONTRIBUTIONS);

  const [showContributionDetail, setShowContributionDetail] = useState(false);

  if (loading) return <LoadingMessage />;

  const panel = v(data, "panelSubject.panel", {});
  const panelSubjectFamily = v(data, "panelSubject.panelSubjectFamily", {});

  const yesNoValues = [
    { codeValue: "Y", codeDescription: "Yes" },
    { codeValue: "N", codeDescription: "No" },
    { codeValue: null, codeDescription: "No" },
    { codeValue: "", codeDescription: "No" },
  ];

  return (
    <React.Fragment>
      {!showContributionDetail && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Menu attached="top">
                <Menu.Item header>{t("Meeting Details")}</Menu.Item>
              </Menu>
              <Segment attached>
                <Form>
                  <Form.Input
                    label={t("Meeting Date")}
                    value={panel.panelDate}
                    type="date"
                    readOnly
                  />
                  <Form.Input
                    label={t("Meeting Type")}
                    value={lookupFormatter(
                      data.lookupCodes,
                      panel.panelType,
                      5181
                    )}
                    readOnly
                  />
                  <Form.Input
                    label={t("Meeting Title")}
                    value={panel.title}
                    readOnly
                  />
                  <Form.Input
                    label={t("Start Time")}
                    value={panel.startTime}
                    readOnly
                  />
                  <Form.Input
                    label={t("Duration")}
                    value={panel.duration}
                    readOnly
                  />
                  <Form.Input
                    label={t("Location Type")}
                    value={lookupFormatter(
                      data.lookupCodes,
                      panel.locationType,
                      6145
                    )}
                    readOnly
                  />
                  {panel.locationType === "ADDRESS" &&
                    panelSubjectFamily.invited === "Y" && (
                    <Form.TextArea
                      style={{ minHeight: 135 }}
                      label={t("Location")}
                      value={panel.address.formatted}
                      readOnly
                    />
                  )}
                  {panel.locationType !== "ADDRESS" &&
                    panelSubjectFamily.invited === "Y" && (
                    <Form.Input
                      label={t("Location")}
                      value={panel.virtualUrl}
                      readOnly
                    />
                  )}
                  {panel.locationType !== "ADDRESS" &&
                    panelSubjectFamily.invited === "Y" && (
                      <Form.TextArea
                        label={t("Additional Details")}
                        value={panel.virtualText}
                        readOnly
                      />
                    )}
                  <Form.Input
                    label={t("Invited")}
                    value={lookupFormatter(
                      yesNoValues,
                      panelSubjectFamily.invited
                    )}
                    readOnly
                  />
                  <Button className={'orange'} as={Link} to={`/children/${pupilId}/meetings`}>
                    {t("Back")}
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column width={10}>
              <Menu attached="top">
                <Menu.Item header>{t("Professional Contribution")}</Menu.Item>
              </Menu>
              <Segment attached>
                {v(data,"contributionListDTO", []).length < 1 &&
                <Message info icon color='purple'>
                  <Icon name="info" />
                  <Message.Header>{t("No records have been found")}</Message.Header>
                </Message>
                }
                {v(data, "contributionListDTO", []).length > 0 &&
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {t("Professional Name")}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("Linked Contribution(s)")}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{t("Action")}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {v(data, "contributionListDTO", []).map((contribution) => (
                      <Table.Row key={contribution.surname}>
                        <Table.Cell>
                          {contribution.forename + " " + contribution.surname}
                        </Table.Cell>
                        <Table.Cell>
                          <LookupCode
                            codeType={111}
                            codeValue={contribution.personType}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {contribution.contributionCount}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => {
                              getContribution({
                                variables: {
                                  subjectId: subjectId,
                                  authorServiceId: contribution.serviceId,
                                },
                              });
                              setShowContributionDetail(true);
                            }}
                            className={"blue"}
                          >
                            {t("View")}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                }
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {showContributionDetail && (
        <React.Fragment>
          <Button
            style={{ marginBottom: "1rem" }}
            className={'orange'}
            onClick={() => {
              setShowContributionDetail(false);
            }}
          >
            {t("Back")}
          </Button>
          <br />
          {v(conData, "contributions", []).map((contribution) => (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Menu attached="top">
                    <Menu.Item header>
                      {t(
                        lookupFormatter(
                          data.lookupCodes,
                          contribution.contributionType,
                          6050
                        )
                      )}
                    </Menu.Item>
                  </Menu>
                  <Segment attached>
                    <Segment>
                      <div
                        style={{ height: "200px", "overflow-y": "scroll" }}
                        dangerouslySetInnerHTML={{
                          __html: contribution.contributionText,
                        }}
                      />
                    </Segment>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                          <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            {t("Actions")}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {/*{v(contribution, "contributionDocuments.files", []).map(*/}
                        {contribution.contributionDocuments.map(
                          (contributionDocument) => (
                            <Table.Row key={contributionDocument.files.id}>
                              <Table.Cell>
                                {contributionDocument.files.filename}
                              </Table.Cell>
                              <Table.Cell>
                                {contributionDocument.files.docSize + "kb"}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <Button
                                  onClick={() => {
                                    createAndDownloadBlobFile(
                                      base64ToArrayBuffer(
                                        contributionDocument.files.base64
                                      ),
                                      contributionDocument.files.filename,
                                      contributionDocument.files.mimeType
                                    );
                                  }}
                                  style={{backgroundColor: '#00AEEF', color:'#fff'}}
                                  className={'light-blue'}
                                >
                                  {t("Download")}
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default MeetingDetails;
