import React, {useState} from "react";
import {Dropdown, Grid, Icon, Message, Pagination, Segment, Table,} from "semantic-ui-react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {useTranslation} from "react-i18next";
import ordinal from "ordinal";
import LoadingMessage from "../../LoadingMessage";
import dateFormatter from "../../DateFormatter";
import LookupCode from "../../LookupCode";
import {v} from "../../utils";
import HelpText from "../../components/HelpText";
import {mergeDeepRight} from "ramda";

const GET_DATA = gql`
  query GetAddress($page: Int!, $size: Int!) {
    portalUser {
      person {
        forename
        surname
        personAddresses(page: $page, size: $size) {
          number
          totalPages
          content {
            ... on PersonAddress {
              addressStartDate
              addressEndDate
              mainAddressInd
              addressType
              commsPreferredMethodOrder
              address {
                addressId
                formatted
              }
            }
          }
        }
      }
    }
  }
`;

function AddressList() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const { data, loading, fetchMore } = useQuery(GET_DATA, {
    variables: { page: 0, size: pageSize },
  });

  if (loading) return <LoadingMessage />;

  if (v(data,"portalUser.person.personAddresses.content",[]).length <= 0)
    return (
      <div>
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  const currentIndicator = (mainAddressInd) => {
    switch (mainAddressInd) {
      case "M":
        return t("Main");
      case "P":
        return t("Previous");
      case "F":
        return t("Future");
      default:
        return mainAddressInd;
    }
  };

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Start Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("End Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Current Indicator")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Communication Method")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
            <Table.HeaderCell>
              <HelpText
                contentArea="Profile"
                contentModule="Profile"
                contentType="HELP"
                contentItem="Address"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {v(data, "portalUser.person.personAddresses.content", []).map(
            ({
              addressStartDate,
              addressEndDate,
              mainAddressInd,
              address,
              addressType,
              commsPreferredMethodOrder,
            }) => (
              <Table.Row key={address.addressId}>
                <Table.Cell>{dateFormatter(addressStartDate)} </Table.Cell>
                <Table.Cell>{dateFormatter(addressEndDate)}</Table.Cell>
                <Table.Cell>{currentIndicator(mainAddressInd)}</Table.Cell>
                <Table.Cell textAlign="center">
                  {!!commsPreferredMethodOrder && (
                    <span className="inline-block rounded p-1 bg-purple-400 w-12 text-center text-white font-bold tracking-wide">
                      {ordinal(commsPreferredMethodOrder)}
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <LookupCode codeType={5002} codeValue={addressType} />
                </Table.Cell>
                <Table.Cell>{address.formatted}</Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.portalUser?.person.personAddresses.number + 1}
              siblingRange={1}
              totalPages={data.portalUser?.person.personAddresses.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return mergeDeepRight(prev, {
                      portalUser: {
                        person: {
                          personAddresses:
                            fetchMoreResult.portalUser.person.personAddresses,
                        },
                      },
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default AddressList;
