import React, {useEffect, useState} from "react";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import request, { setAuthorizationHeader } from "./request";
import fatherhood from "./assets/fatherhood.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "./utils";
import LanguageList from "./LanguageList";

function LoginScreen() {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const [displayStatement, setDisplayStatement] = useState(false);

  const login = () => {
    setIsAuthenticating(true);
    request
      .post("/api/login", { email, password, langKey:localStorage.getItem("locale") })
      .then(({ data }) => {
        setIsAuthenticating(false);
        setError("");

        if (data.mfaEnabled) {
          history.push(`/mfa?token=${data.token}`);
        } else {
          setAuthorizationHeader(data.token);
          history.push("/");
        }
      })
      .catch((error) => {
        setIsAuthenticating(false);
        setError("Incorrect username and/or password.");
        console.log(error);
      });
  };

  useEffect(() => {
    request
      .get("/api/info/PAR_REG_CHK")
      .then(({ data: { active } }) => setDisplayStatement(active === 'Y') || "")
      .catch(console.log);
  }, []);

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">{t("Parents Portal")}</div>
          <Message info hidden={!query.get("auto")}>
            {t("You have been automatically logged out because your session has expired.")}
          </Message>
          <Message info hidden={!query.get("idle")}>
            {t("You have been automatically logged out due to inactivity.")}
          </Message>
          <Message error hidden={!error} onDismiss={() => setError("")}>
            {t(error)}
          </Message>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              login();
            }}
          >
            <Form.Input
              label={t("Email")}
              value={email}
              placeholder={t("Please enter your email...")}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              label={t("Password")}
              value={password}
              placeholder={t("Please enter your password...")}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              positive
              loading={isAuthenticating}
              disabled={isAuthenticating || !email || !password}
            >
              {t("Login")}
            </Button>
          </Form>
          <div className="mt-6">
            <Link className="underline" to="/forgot-password">
              {t("Don't remember your password?")}
            </Link>
          </div>
          <div className="mt-6">
            <Link className="underline" to={displayStatement ? "registration-confidentiality" : "/register"}>
              {t("Not registered? Request an account")}
            </Link>
          </div>
          <div className="mt-6">
            <Link className="underline" to={"/invite-code"}>
              {t("Received an Invite to sign up? Click here to complete your registration")}
            </Link>
          </div>
          <div className="mt-6">
            <Divider />
            <LanguageList />
          </div>
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={fatherhood} alt="" />
      </div>
    </div>
  );
}

export default LoginScreen;
