import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  formatSize,
} from "../../utils";
import FileInput from "../../FileInput";
import { useToasts } from "react-toast-notifications";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import { useTranslation } from "react-i18next";
import RichTextEditor from "../../RichTextEditor";

function ContributionForm({ contribution }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [text, setText] = useState(contribution.text);

  const methods = useForm({
    defaultValues: { text: contribution.text || "" },
  });

  const [updateContribution, { loading: isUpdating }] = useMutation(
    gql`
      mutation($id: ID!, $text: String, $parts: [Upload]!) {
        updateContribution(id: $id, text: $text, parts: $parts) {
          id
          text
          files {
            id
            docSize
            mimeType
            filename
            base64
            application
          }
        }
      }
    `,
    {
      onCompleted: () => {
        addToast(t("Contribution sent"), { appearance: "success" });
        methods.reset({ text: contribution.text || "" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Contribution not sent"), { appearance: "error" });
        });
      },
    }
  );

  const onSubmit = ({ files = [] }) => {
    updateContribution({
      variables: {
        id: contribution.id,
        text,
        parts: files.map(({ file }) => file).filter((file) => file !== ""),
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {contribution.allowText === "Y" && (
          <RichTextEditor
            name="text"
            value={text}
            readOnly={!!contribution.text || contribution.allowText !== "Y"}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        )}
        {contribution.allowAttach === "Y" && <FileInput name="files" />}
        <Button
          loading={isUpdating}
          disabled={isUpdating || !!contribution.text}
          className={'green'}
        >
          {t("Save")}
        </Button>
      </Form>
      {!!contribution.files.length && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
              <Table.HeaderCell>{t("File")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {contribution.files.map((file) => (
              <Table.Row key={file.id}>
                <Table.Cell>{file.id}</Table.Cell>
                <Table.Cell>{file.filename}</Table.Cell>
                <Table.Cell>{file.application}</Table.Cell>
                <Table.Cell>{formatSize(file.docSize)}</Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={() => {
                      createAndDownloadBlobFile(
                        base64ToArrayBuffer(file.base64),
                        file.filename,
                        file.mimeType
                      );
                    }}
                    style={{backgroundColor: '#00AEEF', color:'#fff'}}
                    className={'light-blue'}
                  >
                    {t("Download")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </FormProvider>
  );
}

export default ContributionForm;
