import axios from "axios";

export const setAuthorizationHeader = (token) => {
  if (!!token) {
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

const request = axios;

export default request;
