import React from "react";
import { Item, Form } from "semantic-ui-react";
import { getCurrentSchool } from "../helpers";
import { useTranslation } from 'react-i18next';

function FreeSchoolMealsApplicationChildren({
  children,
  includeChildren,
  setIncludeChildren,
}) {
    const { t } = useTranslation();
  return (
    <React.Fragment>
      <Item.Group divided>
        {children.map((child) => (
          <Item key={child.pupil.pupilId}>
            <Item.Content>
              <Item.Header>
                {child.pupil.forenames} {child.pupil.surname}
              </Item.Header>
              <Item.Meta>
                {getCurrentSchool(child.pupil.edHistory).estabName}
              </Item.Meta>
              <Item.Description>
                  {t('Year Group')} {child.pupil.yearGroup}
              </Item.Description>
              <Item.Extra>
                <Form.Checkbox
                  label={t("Include")}
                  toggle
                  checked={includeChildren.includes(child.pupil.pupilId)}
                  onClick={(_, { checked, value }) => {
                    if (checked) {
                      setIncludeChildren(
                        [child.pupil.pupilId].concat(includeChildren)
                      );
                    } else {
                      setIncludeChildren(
                        includeChildren.filter((pupilId) => pupilId !== value)
                      );
                    }
                  }}
                  value={child.pupil.pupilId}
                />
              </Item.Extra>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </React.Fragment>
  );
}

export default FreeSchoolMealsApplicationChildren;
