import React from "react";
import { Redirect } from "react-router-dom";

function AnonymousRoute({ children }) {
  if (sessionStorage.getItem("token")) {
    return <Redirect to="/" />;
  }

  return children;
}

export default AnonymousRoute;
