import React, { useState } from "react";
import {
  Button,
  Container,
  FormField,
  Message,
  Segment,
  Select,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { v } from "../utils";
import LoadingMessage from "../LoadingMessage";
import { useTranslation } from "react-i18next";
import ReferralSteps from "./ReferralSteps";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  query {
    portalContent(
      contentModule: "Referrals"
      contentType: "MENU"
      contentArea: "Referrals"
    ) {
      contentId
      contentValue
      contentItem
      mappedLookup
    }
    portalUser {
      person {
        personId
        pupilGuardian {
          hashCode
          pupil {
            serviceDefinition {
              id
              referrals {
                isActive
                referralType
              }
            }
            fullName
          }
        }
      }
    }
  }
`;

function Referrals() {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA);
  const [activeReferrals, setActiveReferrals] = useState([]);
  const [showSteps, setShowSteps] = useState(false);
  const [referralType, setReferralType] = useState("");
  const [serviceId, setServiceId] = useState(undefined);

  if (loading) return <LoadingMessage />;

  return (
    <Container>
      {!showSteps && (
        <Segment attached="top">
          <h3>
            {t("Referrals")}
            <HelpText
                contentArea="Referrals options"
                contentModule="Referrals"
                contentType="HELP"
                contentItem="Referral Select Child"
                accessLevel="Private"
            />
          </h3>
        </Segment>
      )}
      {!showSteps && (
        <Segment attached="bottom">
          <FormField>
            <label>
              <h4>{t("Child")}</h4>
            </label>
            <Select
              fluid
              value={serviceId}
              options={v(data, "portalUser.person.pupilGuardian", []).map(
                (pg) => ({
                  value: pg.pupil.serviceDefinition.id,
                  text: t(pg.pupil.fullName),
                })
              )}
              search
              onChange={(e, { value }) => {
                setServiceId(value);
                setActiveReferrals(
                  data.portalUser.person.pupilGuardian.find(
                    (p) => p.pupil.serviceDefinition.id === value
                  ).pupil.serviceDefinition.referrals
                );
              }}
            />
          </FormField>
        </Segment>
      )}
      {!!serviceId && !showSteps && (
        <div>
          {v(data, "portalContent", []).map((pc) => (
            <div
              className="border rounded bg-white"
              key={`${pc.contentId}`}
              style={{ marginBottom: "1rem" }}
            >
              <div className="text-3xl p-4 tracking-tight leading-10 font-black">
                {t(pc.contentItem)}
                <HelpText
                    contentArea="Referrals options"
                    contentModule="Referrals"
                    contentType="HELP"
                    contentItem={(pc.contentItem === "ALN Assessment Request" ? "Referral Sen Assessment Request" :'Referral Elective Home Education')}
                    accessLevel="Private"
                />
              </div>
              <div className="p-4">
                <div className="leading-5 mb-6">{t(pc.contentValue)}</div>
              </div>
              <div className="p-4">
                {!!activeReferrals.find(
                  (r) => r.referralType === pc.mappedLookup && r.isActive
                ) && (
                  <Message color="blue">
                    {t("A referral of type")} {t(pc.contentItem)}{" "}
                    {t("exists for this child.")}
                  </Message>
                )}
                {!activeReferrals.find(
                  (r) => r.referralType === pc.mappedLookup && r.isActive
                ) && (
                  <Button
                    as={Link}
                    style={{backgroundColor: '#00AEEF', color:'#fff'}}
                    to="/referrals/details"
                    onClick={() => {
                      setReferralType(pc.mappedLookup);
                      setShowSteps(true);
                    }}
                  >
                    {t("Make Request")}
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {showSteps && (
        <ReferralSteps
          setShowSteps={setShowSteps}
          referralType={referralType}
          serviceId={serviceId}
          setServiceId={setServiceId}
        />
      )}
    </Container>
  );
}

export default Referrals;
