import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Icon,
  Message,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "./request";
import Input from "./Input";
import Dropdown from "./Dropdown";
import { capitalise, lookupFormatter, lookupToOptions } from "./utils";
import dateFormatter from "./DateFormatter";
import { useTranslation } from "react-i18next";
import LanguageList from "./LanguageList";
import HelpText from "./components/HelpText";
import { useHistory, useLocation } from "react-router-dom";

const searchEstabValidations = yup.object({
  estabNameQuery: yup
    .string()
    .required("Please enter a School name")
    .min(2, "Please enter at least 2 characters"),
});

function SearchAddress({ setAddresses }) {
  const { t } = useTranslation();
  const searchAddressValidations = yup.object({
    postcode: yup
      .string()
      .required(t("Please enter a valid postcode"))
      .min(3, t("The postcode must be at least 3 characters long")),
  });

  const searchCAVForm = useForm({
    resolver: yupResolver(searchAddressValidations),
  });

  const [isSearchingCAV, setIsSearchingCAV] = useState(false);

  const searchCAV = ({ postcode }) => {
    setIsSearchingCAV(true);
    request
      .get("/api/cav", { params: { postcode } })
      .then(({ data }) => {
        setIsSearchingCAV(false);
        setAddresses(data);
      })
      .catch((error) => {
        setIsSearchingCAV(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchCAVForm}>
      <Form
        onSubmit={searchCAVForm.handleSubmit(searchCAV)}
        style={{ marginBottom: 14 }}
      >
        <Input name="postcode" label={t("Postcode")} />
        <Button className={"light-blue"} style={{backgroundColor: '#00AEEF', color:'#fff'}} disabled={isSearchingCAV} loading={isSearchingCAV}>
          <Icon name="search" />
          {t("Find Address")}
        </Button>
      </Form>
    </FormProvider>
  );
}

function AddChild({ onCancel, genders, relationships, append }) {
  const { t } = useTranslation();
  const childValidations = yup.object({
    firstName: yup.string().required(t("Required")),
    surname: yup.string().required(t("Required")),
    dob: yup.string().required(t("Required")),
    gender: yup.string().required(t("Required")),
    sameAddress: yup.string().required(t("Required")),
    relationship: yup.string().required(t("Required")),
    address: yup.string().when("sameAddress", {
      is: "N",
      then: yup.string().required(t("Required")),
    }),
  });

  const form = useForm({
    resolver: yupResolver(childValidations),
  });

  const [addresses, setAddresses] = useState([]);

  return (
    <>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((values) => {
            append(values);
            onCancel();
          })}
        >
          <Input name="firstName" label={t("First Name")} />
          <Input name="surname" label={t("Surname")} />
          <Input name="dob" label={t("Date of Birth")} type="date" />
          <Dropdown
            name="gender"
            label={t("Sex")}
            options={lookupToOptions(6187)(genders)}
          />
          <Dropdown
            name="relationship"
            label={t("Relationship to Child/Dependant")}
            options={lookupToOptions(108)(relationships)}
          />
          <Dropdown
            name="sameAddress"
            label={t("Same address as Parent/Guardian")}
            options={[
              { value: "Y", text: "Yes" },
              { value: "N", text: "No" },
            ]}
          />
          <button type="submit" hidden />
        </Form>
      </FormProvider>
      {form.watch("sameAddress", "N") !== "Y" && (
        <>
          <SearchAddress setAddresses={setAddresses} />
          <FormProvider {...form}>
            <Form
              onSubmit={form.handleSubmit((values) => {
                append(values);
                onCancel();
              })}
            >
              <Dropdown
                name="address"
                label={t("Address")}
                options={addresses.map(({ formatted, uprn }) => ({
                  value: uprn,
                  text: formatted,
                }))}
              />
              <button type="submit" hidden />
            </Form>
          </FormProvider>
        </>
      )}
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((values) => {
            append(values);
            onCancel();
          })}
        >
          <Button positive>{t("Save")}</Button>
          <Button className={'orange'} type="button" onClick={onCancel}>
            {t("Cancel")}
          </Button>
        </Form>
      </FormProvider>
    </>
  );
}

function SearchEstab({ setEstabs, setEstabListModalOpen }) {
  const { t } = useTranslation();
  const searchEstabForm = useForm({
    resolver: yupResolver(searchEstabValidations),
  });

  const [isSearchingEstabs, setIsSearchingEstabs] = useState(false);

  const searchEstab = ({ estabNameQuery }) => {
    setIsSearchingEstabs(true);
    request
      .get("/api/search-estab", { params: { query: estabNameQuery } })
      .then(({ data }) => {
        setIsSearchingEstabs(false);
        setEstabs(data);
        setEstabListModalOpen(true);
      })
      .catch((error) => {
        setIsSearchingEstabs(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchEstabForm}>
      <Form
        onSubmit={searchEstabForm.handleSubmit(searchEstab)}
        style={{ marginBottom: 14 }}
      >
        <Input
          name="estabNameQuery"
          label={t("School/Institution Name")}
          style={{ marginTop: "1rem" }}
        />
        <Button className={"light-blue"} style={{backgroundColor: '#00AEEF', color:'#fff'}} disabled={isSearchingEstabs} loading={isSearchingEstabs}>
          <Icon name="search" />
          {t("Find School")}
        </Button>
      </Form>
    </FormProvider>
  );
}

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
  ERROR: 3,
};

function RegisterScreen() {
  const { t } = useTranslation();
  const [estabs, setEstabs] = useState([]);
  const [isEstabListModalOpen, setEstabListModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const transformData = (rows) => {
    return rows.map((row) => {
      return {
        leaNo: row.leaNo,
        dfeeNo: row.dfeeNo,
        estabName: row.estabName,
        alternativeName: row.alternativeName,
        serviceId: row.serviceId,
        addressText:
          capitalise(
            (row.unitNo ? row.unitNo + ", " : "") +
              (row.unitName ? row.unitName + ", " : "") +
              (row.houseNo ? row.houseNo + " " : "") +
              (row.street ? row.street : "") +
              (row.mainRoad ? ", " + row.mainRoad : "") +
              (row.district ? ", " + row.district : "") +
              (row.town ? ", " + row.town : "") +
              (row.county ? ", " + row.county : "")
          ) + (row.postcode ? ", " + row.postcode : ""),
      };
    });
  };

  const validations = yup.object({
    title: yup.string().required(t("Required")),
    firstName: yup
      .string()
      .required(t("Required"))
      .max(30, t("30 character limit exceeded")),
    lastName: yup
      .string()
      .required(t("Required"))
      .max(30, t("30 character limit exceeded")),
    address: yup.string().required(t("Please search an address by postcode")),
    email: yup
      .string()
      .required(t("Required"))
      .email(t("Please enter a valid email")),
    mobileNumber: yup
      .string()
      .required(t("Please enter a mobile number"))
      .test(
        "is-integer",
        "Digits only, no spaces or special characters",
        (value) => /^\d+$/.test(value)
      )
      .length(11, t("Must be 11 digits long")),
    password: yup.string().required(t("Required")),
    confirmPassword: yup
      .string()
      .required(t("Required"))
      .oneOf([yup.ref("password")], t("Passwords must match")),
    children: yup.array().required().min(1),
  });

  const form = useForm({
    resolver: yupResolver(validations),
  });

  const [titles, setTitles] = useState([]);
  const [genders, setGenders] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [status, setStatus] = useState(STATUS.INIT);
  const [isAdding, setIsAdding] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "children",
  });

  useEffect(() => {
    request
      .get("/api/info/PAR_REG_CHK")
      .then(({ data: { active } }) => {
        if (active === 'Y' && location.state?.agreeInd !== 'Y') {
          history.push('/registration-confidentiality');
        }
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    request.get("/api/lookup-codes/103").then(({ data }) => setTitles(data));

    request.get("/api/lookup-codes/6187").then(({ data }) => setGenders(data));

    request
      .get("/api/lookup-codes/108")
      .then(({ data }) => setRelationships(data));
  }, []);

  const onSubmit = (values) => {
    setStatus(STATUS.IN_PROG);
    request
      .post("/api/register", { ...values, agreeInd: location.state?.agreeInd || 'N' })
      .then(() => {
        setStatus(STATUS.DONE);
      })
      .catch((e) => {
        if (
          e.response.status !== 400 &&
          e.response.data.field === undefined &&
          e.response.data.error === undefined
        ) {
          setStatus(STATUS.ERROR);
        } else {
          setStatus(STATUS.INIT);
          form.setError(e.response.data.field, {
            type: "server",
            message: e.response.data.error,
          });
        }
      });
  };

  if (status === STATUS.DONE) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <Message.Header>{t("Account Created")}</Message.Header>
            {t("Please check your email for an activation link.")}
          </Message.Content>
        </Message>
      </Container>
    );
  }

  return (
    <Container>
      {fields.map((field, index) => (
        <div key={field.id}>
          <input
            type="text"
            name={`children[${index}].firstName`}
            ref={form.register}
            defaultValue={field.firstName}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].surname`}
            ref={form.register}
            defaultValue={field.surname}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].dob`}
            ref={form.register}
            defaultValue={field.dob}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].gender`}
            ref={form.register}
            defaultValue={field.gender}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].relationship`}
            ref={form.register}
            defaultValue={field.relationship}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].sameAddress`}
            ref={form.register}
            defaultValue={field.sameAddress}
            hidden
          />
          <input
            type="text"
            name={`children[${index}].address`}
            ref={form.register}
            defaultValue={field.address}
            hidden
          />
        </div>
      ))}
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column className="pt-6">
            <Segment attached="top">
              <div className="text-2xl font-bold">
                {t("Your Details")}
                <HelpText
                  contentArea="Portal Registration"
                  contentModule="Login"
                  contentType="HELP"
                  contentItem="Your Details"
                  accessLevel="Public"
                />
              </div>
            </Segment>
            <Segment attached>
              {status === STATUS.ERROR && (
                <Message icon error>
                  <Icon name="warning" />
                  <Message.Content>
                    <Message.Header>
                      {t("Account Registration Failed")}
                    </Message.Header>
                    {t("Unable to register an account, please try again.")}
                  </Message.Content>
                </Message>
              )}
              <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                  <input
                    type="text"
                    hidden
                    ref={form.register}
                    name="estabServiceId"
                  />
                  <Dropdown
                    name="title"
                    label={t("Title")}
                    options={lookupToOptions(103)(titles)}
                  />
                  <Input name="firstName" label={t("First Name")} />
                  <Input name="lastName" label={t("Surname")} />
                  <button type="submit" hidden />
                </Form>
              </FormProvider>
              <SearchAddress setAddresses={setAddresses} />
              <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                  <Dropdown
                    name="address"
                    label={t("Address")}
                    options={addresses.map(({ formatted, uprn }) => ({
                      value: uprn,
                      text: formatted,
                    }))}
                  />
                  <Input name="email" label={t("Email")} />
                  <Input name="mobileNumber" label={t("Mobile Number")} />
                  <button type="submit" hidden />
                </Form>
              </FormProvider>
              <SearchEstab
                setEstabs={setEstabs}
                setEstabListModalOpen={setEstabListModalOpen}
              />
              <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                  <Input
                    name="estabName"
                    ref={form.register}
                    label={t("School/Institution")}
                    type="text"
                    readOnly
                  />
                  <Input
                    name="password"
                    label={t("Password")}
                    type="password"
                  />
                  <Input
                    name="confirmPassword"
                    label={t("Confirm Password")}
                    type="password"
                  />
                  <button type="submit" hidden />
                </Form>
              </FormProvider>
              {!!form.errors.children && (
                <Message error>
                  <Message.Content>
                    {t("Please add at least one Child/Dependant")}
                  </Message.Content>
                </Message>
              )}
              <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                  <Button
                    style={{backgroundColor:'#0054A4', color:'#FFF'}}
                    disabled={status === STATUS.IN_PROG}
                    loading={status === STATUS.IN_PROG}
                  >
                    {t("Send Request")}
                  </Button>
                  <Button className={'orange'} as={Link} to="/login">
                    {t("Cancel")}
                  </Button>
                </Form>
              </FormProvider>
            </Segment>
          </Grid.Column>
          <Grid.Column className="pt-6">
            <Segment attached="top">
              <div className="text-2xl font-bold">
                {t("Add a Child/Dependant")}
                <HelpText
                  contentArea="Login"
                  contentModule="Login"
                  contentType="HELP"
                  contentItem="Add a Child/Dependant"
                  accessLevel="Public"
                />
              </div>
            </Segment>
            <Segment attached>
              {isAdding && (
                <AddChild
                  genders={genders}
                  relationships={relationships}
                  onCancel={() => setIsAdding(false)}
                  append={append}
                />
              )}
              {!isAdding && (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t("First Name")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Surname")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Birthdate")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Sex")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {fields.map((field, index) => (
                        <Table.Row key={field.id}>
                          <Table.Cell>{field.firstName}</Table.Cell>
                          <Table.Cell>{field.surname}</Table.Cell>
                          <Table.Cell>{dateFormatter(field.dob)}</Table.Cell>
                          <Table.Cell>
                            {lookupFormatter(genders, field.gender, 6187)}
                          </Table.Cell>
                          <Table.Cell collapsing>
                            <Button icon onClick={() => remove(index)} negative>
                              <Icon name="trash" />
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <Button className={'purple'} onClick={() => setIsAdding(true)}>
                    {t("Add")}
                  </Button>
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Divider />
            <LanguageList />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal open={isEstabListModalOpen} size="fullscreen" scrolling>
        <Modal.Header>{t("Select School/Institution")}</Modal.Header>
        <Modal.Content scrolling>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Alternative Name")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Lea No")}</Table.HeaderCell>
                <Table.HeaderCell>{t("DfE No")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Select")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {transformData(estabs).map((estab) => (
                <Table.Row key={estab.serviceId}>
                  <Table.Cell>{estab.estabName}</Table.Cell>
                  <Table.Cell>{estab.alternativeName}</Table.Cell>
                  <Table.Cell>{estab.leaNo}</Table.Cell>
                  <Table.Cell>{estab.dfeeNo}</Table.Cell>
                  <Table.Cell>{estab.addressText}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      icon
                      onClick={() => {
                        form.setValue("estabServiceId", estab.serviceId);
                        form.setValue("estabName", estab.estabName);
                        console.log("adfg " + estab.estabName);
                        setEstabListModalOpen(false);
                      }}
                      positive
                    >
                      <Icon name="check" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setEstabListModalOpen(false);
            }}
            className={"orange"}
          >
            <Icon name="arrow circle left" />
            {"Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default RegisterScreen;
