import React, { useContext } from "react";
import { List } from "semantic-ui-react";
import { LanguageContext } from "./LanguageContext";

function LanguageList() {
  const { language, setLanguage, languages } = useContext(LanguageContext);

  return (
    <List horizontal divided>
      {languages.map((lang) => (
        <List.Item
          key={lang.id}
          onClick={() => setLanguage(lang.locale)}
          as="a"
        >
          <List.Content>
            {lang.locale === language && (
              <List.Header>{lang.description}</List.Header>
            )}
            {lang.locale !== language && (
              <List.Description>{lang.description}</List.Description>
            )}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
}

export default LanguageList;
