import { gql } from "apollo-boost";

export const PLANS = gql`
  query Plans($pupilId: Int!, $page: Int!, $size: Int!) {
    pupil(pupilId: $pupilId) {
      pupilPlans(page: $page, size: $size) {
        number
        totalPages
        content {
          ... on PupilPlan {
            id
            issueDate
            type
            title
          }
        }
      }
    }
  }
`;

export const LOOKUP_CODES = gql`
  query LookupCode($codeTypes: [Int!]!) {
    lookupCodes(codeTypes: $codeTypes) {
      codeType
      codeValue
      codeDescription
    }
  }
`;
