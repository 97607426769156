import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoadingMessage from "../../LoadingMessage";
import { Grid } from "semantic-ui-react";
import { v } from "../../utils";
import { useTranslation } from 'react-i18next';

const PUPIL = gql`
  query Pupil($pupilId: Int!) {
    pupil(pupilId: $pupilId) {
      edHistory {
        isCurrent
      }
      serviceDefinition {
        panelSubjectList {
          panel {
            isUpcoming
          }
        }
      }
      communicationList {
        id
      }
    }
  }
`;

function ChildOverview() {
  const { pupilId } = useParams();

  const { t } = useTranslation();
  const { loading, data } = useQuery(PUPIL, {
    variables: { pupilId },
  });

  if (loading) return <LoadingMessage />;

  return (
    <Grid>
      <Grid.Column width={8}>
        <div className="bg-white p-4 rounded">
          <div className="text-xl font-bold border-b pb-2">
            {t('Education History Records')}
          </div>
          <Link to={`/children/${pupilId}/education-history`}>
            <div className="text-center text-4xl font-bold py-10">
              {data.pupil.edHistory.length}
            </div>
          </Link>
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <div className="bg-white p-4 rounded">
          <div className="text-xl font-bold border-b pb-2">
            {t('Upcoming Meetings')}
          </div>
          <Link to={`/children/${pupilId}/meetings`}>
            <div className="text-center text-4xl font-bold py-10">
              {
                v(data, "pupil.serviceDefinition.panelSubjectList", []).filter(
                  (panelSubject) => panelSubject.panel.isUpcoming
                ).length
              }
            </div>
          </Link>
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <div className="bg-white p-4 rounded">
          <div className="text-xl font-bold border-b pb-2">{t('Communications')}</div>
          <Link to={`/children/${pupilId}/communications`}>
            <div className="text-center text-4xl font-bold py-10">
              {v(data, "pupil.communicationList", []).length}
            </div>
          </Link>
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default ChildOverview;
