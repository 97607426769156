import { useQuery } from "@apollo/react-hooks";
import { LOOKUP_CODES } from "./graphql/Queries";
import { v } from "./utils";
import { client } from "./App";
import { useTranslation } from "react-i18next";

function LookupCode({ codeType, codeValue }) {
  const { loading, error, data } = useQuery(LOOKUP_CODES, {
    variables: { codeTypes: [codeType] },
  });
  const { t } = useTranslation();

  if (loading || error) return codeValue || "";

  const found = v(data, "lookupCodes", []).find(
    (lookupCode) =>
      lookupCode.codeValue === codeValue && lookupCode.codeType === codeType
  );

  if (!found) return codeValue || "";

  return t(found.codeDescription) || "";
}

export async function lookupCode(codeType, codeValue) {
  const { data } = await client().query({
    query: LOOKUP_CODES,
    variables: { codeTypes: [codeType] },
  });

  const found = data.lookupCodes.find(
    (lookupCode) =>
      lookupCode.codeValue === codeValue && lookupCode.codeType === codeType
  );

  if (found) return found.codeDescription;

  return codeValue;
}

export default LookupCode;
