import React from "react";
import { Redirect } from "react-router-dom";

function AuthenticatedRoute({ children }) {
  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return children;
}

export default AuthenticatedRoute;
