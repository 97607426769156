import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import HelpText from "../components/HelpText";

function Consent({ lookupCodes, showAgencyConsent }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
        <h5>All fields marked with a <span style={{color: 'red'}}>*</span> are mandatory, you will not be able to submit the referral until these fields have been completed.</h5>
        <Segment attached="top">
        <h3>
            {t('Consent')}
            <HelpText
                contentArea="Referral Consent"
                contentModule="Referrals"
                contentType="HELP"
                contentItem="Referral Consent"
                accessLevel="Private"
            />
        </h3>
      </Segment>
      <Segment attached>
        <Form>
          <Dropdown
            required={true}
            name="consentGiven"
            label={t("Consent Given")}
            options={lookupCodes
              .filter(({ codeType }) => codeType === 5132)
              .map((lookupCode) => ({
                key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
                value: lookupCode.codeValue,
                text: t(lookupCode.codeDescription),
              }))}
          />
          <TextArea name="consentConditions" label={t("Consent Conditions")} />
          {showAgencyConsent === 'Y' &&
            <TextArea
              name="nonConsentingAgencies"
              label={t("Agencies Without Consent")}
            />
          }

        </Form>
      </Segment>
      <Segment attached="bottom">
        <Button as={Link} to="/referrals/notes" style={{backgroundColor:'#FF9900', color:'#FFF'}}>
            {t('Back')}
        </Button>
      </Segment>
    </React.Fragment>
  );
}

export default Consent;
