import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import { v } from "../utils";
import Notes from "./Notes";
import ReferralDetails from "./ReferralDetails";
import Consent from "./Consent";
import { Button, Grid, Step } from "semantic-ui-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import ProfessionalsInvolved from "./ProfessionalsInvolved";
import { useTranslation } from "react-i18next";

const GET_DATA = gql`
    query {
        portalContent(
            contentModule: "Referrals"
            contentType: "MENU"
            contentArea: "Referrals"
        ) {
            contentValue
            contentItem
            mappedLookup
        }
        lookupCodes(codeTypes: [1001, 1126, 729, 730, 5132, 6080, 555]) {
            codeType
            codeValue
            codeDescription
        }
        info(infoId: "PARAGENCNSNT"){
            active
        }
        portalUser {
            person {
                personId
                pupilGuardian {
                    hashCode
                    pupil {
                        serviceDefinition {
                            id
                            referrals {
                                isActive
                                referralType
                            }
                        }
                        fullName
                    }
                }
            }
        }
    }
`;

const schema = yup.object({
  consentGiven: yup.string().required("Required"),
  referralReason: yup.string().required("Required"),
  professionals: yup.array().of(
    yup.object({
      type: yup.string().required("Required"),
      name: yup.string().required("Required"),
    })
  ),
});

function ReferralSteps({
  setShowSteps,
  referralType,
  serviceId,
  setServiceId,
}) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { pathname } = useLocation();

  const { loading, data } = useQuery(GET_DATA);

  const [createReferral, { loading: creatingReferral }] = useMutation(
    gql`
      mutation ($input: CreateReferralInput!, $files: [Upload]!) {
        createReferral(input: $input, files: $files) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        addToast(t("Referral created"), { appearance: "success" });
        setShowSteps(false);
        setServiceId("");
        history.push(`/referrals`);
      },
      onError: (error) => {
        error.graphQLErrors.forEach(() => {
          addToast(t("Referral not created"), { appearance: "error" });
        });
      },
      refetchQueries: [{ query: GET_DATA }],
    }
  );

  const onSubmit = (data) => {
    createReferral({
      variables: {
        input: {
          serviceId: serviceId,
          referralType: referralType,
          referralReason: data.referralReason,
          initialAction: data.initialAction,
          noteTitle: data.noteTitle,
          noteHtml: data.noteHtml,
          consentGiven: data.consentGiven,
          consentConditions: data.consentConditions,
          nonConsentingAgencies: data.nonConsentingAgencies,
          referralProfs: v(data, "professionals", []).map((referralProf) => ({
            profType: referralProf.type,
            profName: referralProf.name,
            dateLastSeen:
              referralProf.dateLastSeen !== ""
                ? referralProf.dateLastSeen
                : null,
            address: !!referralProf.postcode ? {
              houseNo: referralProf.houseNo,
              unitNo: referralProf.unitNo,
              unitName: referralProf.unitName,
              street: referralProf.street,
              district: referralProf.district,
              town: referralProf.town,
              county: referralProf.county,
              postcode: referralProf.postcode,
              easting: referralProf.easting,
              northing: referralProf.northing,
              leaNo: referralProf.leaNo,
              osapr: referralProf.osapr,
              wardCode: referralProf.wardCode,
              wardName: referralProf.wardName,
              uprn: referralProf.uprn,
              country: referralProf.country,
              azRef: referralProf.azRef,
            } : null,
          })),
        },
        files: !data.files
          ? []
          : data.files.map(({ file }) => file).filter((file) => file !== ""),
      },
    });
  };

  if (loading) return <LoadingMessage />;

  return (
    <div>
      <Grid>
        <Grid.Column width={4}>
          <Step.Group vertical>
            <Step
              active={pathname === "/referrals/details"}
              as={Link}
              to="/referrals/details"
            >
              <Step.Content>
                <Step.Title>{t("Referral Details")}</Step.Title>
                <Step.Description>
                  {t("Enter the referral details")}
                </Step.Description>
              </Step.Content>
            </Step>

            <Step
              active={pathname === "/referrals/professionals"}
              as={Link}
              to="/referrals/professionals"
            >
              <Step.Content>
                <Step.Title>{t("Professionals Involved")}</Step.Title>
                <Step.Description>
                  {t("Enter known professionals")}
                </Step.Description>
              </Step.Content>
            </Step>

            <Step
              active={pathname === "/referrals/notes"}
              as={Link}
              to="/referrals/notes"
            >
              <Step.Content>
                <Step.Title>{t("Additional Information")}</Step.Title>
                <Step.Description>{t("Add notes")}</Step.Description>
              </Step.Content>
            </Step>

            <Step
              active={pathname === "/referrals/consent"}
              as={Link}
              to="/referrals/consent"
            >
              <Step.Content>
                <Step.Title>{t("Consent")}</Step.Title>
                <Step.Description>{t("Give consent")}</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          <div>
            <Button
              as={Link}
              to={`/referrals`}
              className={'orange'}
              onClick={() => {
                methods.reset();
                setShowSteps(false);
                setServiceId("");
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              disabled={creatingReferral}
              className={'green'}
            >
              {t("Submit")}
            </Button>
          </div>
        </Grid.Column>

        <Grid.Column width={12}>
          <FormProvider {...methods}>
            <div
              className={classNames({
                hidden: pathname !== "/referrals/details",
              })}
            >
              <ReferralDetails lookupCodes={v(data, "lookupCodes", [])} />
            </div>
            <div
              className={classNames({
                hidden: pathname !== "/referrals/notes",
              })}
            >
              <Notes />
            </div>
            <div
              className={classNames({
                hidden: pathname !== "/referrals/professionals",
              })}
            >
              <ProfessionalsInvolved lookupCodes={v(data, "lookupCodes", [])} />
            </div>
            <div
              className={classNames({
                hidden: pathname !== "/referrals/consent",
              })}
            >
              <Consent lookupCodes={v(data, "lookupCodes", [])} showAgencyConsent={v(data, "info.active", "Y")} />
            </div>
          </FormProvider>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default ReferralSteps;
