import React, { useState } from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import PlanDetails from "./PlanDetails";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Message,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { PLANS } from "../../graphql/Queries";
import LookupCode from "../../LookupCode";
import dateFormatter from "../../DateFormatter";
import { useTranslation } from "react-i18next";
import HelpText from "../../components/HelpText";
import {v} from "../../utils";

function Plans() {
  const { pupilId } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  const { loading, data, fetchMore } = useQuery(PLANS, {
    variables: { pupilId, page: 0, size: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingMessage />;

  if (v(data,"pupil.pupilPlans.content",[]).length <= 0)
    return (
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
    );

  return (
    <React.Fragment>
      <Switch>
        <Route path="/children/:pupilId/plans/:planId">
          <PlanDetails />
        </Route>
        <Route>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Date Issued")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                <Table.HeaderCell>
                  <HelpText
                    contentArea="Child Record"
                    contentModule="Children"
                    contentType="HELP"
                    contentItem="Plans List"
                    accessLevel="Private"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.pupil.pupilPlans.content.map((pupilPlan) => (
                <Table.Row key={pupilPlan.id}>
                  <Table.Cell>{pupilPlan.title}</Table.Cell>
                  <Table.Cell>
                    <LookupCode codeType={6017} codeValue={pupilPlan.type} />
                  </Table.Cell>
                  <Table.Cell>{dateFormatter(pupilPlan.issueDate)}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      as={Link}
                      style={{backgroundColor:'#0054A4', color:'#FFF'}}
                      className={"blue"}
                      to={`/children/${pupilId}/plans/${pupilPlan.id}`}
                    >
                      {t("View")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <br />
                <br />
                <Pagination
                  boundaryRange={1}
                  activePage={data.pupil?.pupilPlans.number + 1}
                  siblingRange={1}
                  totalPages={data.pupil?.pupilPlans.totalPages}
                  onPageChange={(e, { activePage }) =>
                    fetchMore({
                      variables: {
                        page: activePage - 1,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const { pupil: prevPupil } = prev;
                        const pupil = Object.assign({}, prevPupil, {
                          pupilPlans: fetchMoreResult.pupil.pupilPlans,
                        });
                        return Object.assign({}, prev, { pupil });
                      },
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column width={2} style={{ textAlign: "center" }}>
                <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                  {t("Record per Page")}
                </label>
                <Segment style={{ padding: "10px" }}>
                  <Dropdown
                    value={pageSize}
                    name="pageSize"
                    options={[
                      { value: 10, text: "10" },
                      { value: 25, text: "25" },
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                    ]}
                    onChange={(_, { value }) => setPageSize(value)}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Plans;
