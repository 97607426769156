import React, { useState } from "react";
import {
  Redirect,
  Switch,
  Link,
  NavLink,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Button, Step, Icon } from "semantic-ui-react";
import FreeSchoolMealsApplicationDetails from "./FreeSchoolMealsApplicationDetails";
import FreeSchoolMealsApplicationChildren from "./FreeSchoolMealsApplicationChildren";
import { useQuery, useMutation } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import { gql } from "apollo-boost";
import FreeSchoolMealsApplicationSummary from "./FreeSchoolMealsApplicationSummary";
import FreeSchoolMealsApplicationDeclaration from "./FreeSchoolMealsApplicationDeclaration";
import { v } from "../utils";
import { useTranslation } from 'react-i18next';

const GET_DATA = gql`
  {
    portalUser {
      person {
        forename
        surname
        niNumber
        activeBenefitClaim(claimType: "FSM") {
          id
          claimType
          inProgress
          pupilClaims {
            pupilId
          }
        }
        personContacts {
          contactValue
          contactCode
          isCurrent
          isMain
          startDate
        }
        personAddresses {
          isCurrent
          address {
            formatted
          }
        }
        pupilGuardians {
          pupil {
            pupilId
            forenames
            surname
            birthdate
            yearGroup
            edHistory {
              isCurrent
              establishment {
                estabName
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_BENEFIT_CLAIM = gql`
  mutation CreateBenefitClaim(
    $claimType: String!
    $pupilIds: [Int]!
    $inProgress: Int!
  ) {
    createBenefitClaim(
      claimType: $claimType
      pupilIds: $pupilIds
      inProgress: $inProgress
    ) {
      id
    }
  }
`;

const UPDATE_BENEFIT_CLAIM = gql`
  mutation UpdateBenefitClaim($id: Int!, $pupilIds: [Int]!, $inProgress: Int!) {
    updateBenefitClaim(id: $id, pupilIds: $pupilIds, inProgress: $inProgress) {
      id
    }
  }
`;

function FreeSchoolMealsApplication() {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA, {
    onCompleted: ({ portalUser }) => {
      setIncludeChildren(
        v(portalUser, ["person", "activeBenefitClaim", "pupilClaims"], []).map(
          ({ pupilId }) => pupilId
        )
      );
    },
  });

  const { pathname } = useLocation();
  const history = useHistory();
  const [includeChildren, setIncludeChildren] = useState([]);
  const [agreedDeclaration, setAgreedDeclaration] = useState(false);
  const [agreedECS, setAgreedECS] = useState(false);
  const [createBenefitClaim, { loading: creating }] = useMutation(
    CREATE_BENEFIT_CLAIM,
    {
      refetchQueries: [{ query: GET_DATA }],
    }
  );
  const [updateBenefitClaim, { loading: updating }] = useMutation(
    UPDATE_BENEFIT_CLAIM,
    {
      refetchQueries: [{ query: GET_DATA }],
    }
  );

  const routes = [
    "/free-school-meals/apply",
    "/free-school-meals/apply/children",
    "/free-school-meals/apply/summary",
    "/free-school-meals/apply/declaration",
  ];

  const isFirstPage = () => {
    return routes.indexOf(pathname) === 0;
  };

  const isLastPage = () => {
    return routes.indexOf(pathname) === routes.length - 1;
  };

  const nextPage = () => {
    return isLastPage()
      ? routes[routes.length - 1]
      : routes[routes.indexOf(pathname) + 1];
  };

  const previousPage = () => {
    return isFirstPage() ? routes[0] : routes[routes.indexOf(pathname) - 1];
  };

  if (loading) return <LoadingMessage />;

  const benefitClaimId = v(
    data,
    ["portalUser", "person", "activeBenefitClaim", "id"],
    0
  );

  const inProgress = v(
    data,
    ["portalUser", "person", "activeBenefitClaim", "inProgress"],
    0
  );

  if (benefitClaimId > 0 && inProgress < 1) {
    return <Redirect to="/free-school-meals" />;
  }

  return (
    <React.Fragment>
      <Step.Group fluid>
        <Step as={NavLink} to="/free-school-meals/apply" exact>
          <Icon name="mobile alternate" />
          <Step.Content>
            <Step.Title>{t('Details')}</Step.Title>
          </Step.Content>
        </Step>

        <Step as={NavLink} to="/free-school-meals/apply/children">
          <Icon name="users" />
          <Step.Content>
            <Step.Title>{t('Children')}</Step.Title>
          </Step.Content>
        </Step>

        <Step as={NavLink} to="/free-school-meals/apply/summary">
          <Icon name="info" />
          <Step.Content>
            <Step.Title>{t('Summary')}</Step.Title>
          </Step.Content>
        </Step>

        <Step as={NavLink} to="/free-school-meals/apply/declaration">
          <Icon name="signup" />
          <Step.Content>
            <Step.Title>{t('Declaration')}</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>

      <div className="bg-white p-6 rounded border">
        <Switch>
          <Route path="/free-school-meals/apply" exact>
            <FreeSchoolMealsApplicationDetails
              person={data ? data.portalUser.person: null}
            />
          </Route>
          <Route path="/free-school-meals/apply/children">
            <FreeSchoolMealsApplicationChildren
              children={data? data.portalUser.person.pupilGuardians : null}
              includeChildren={includeChildren}
              setIncludeChildren={setIncludeChildren}
            />
          </Route>
          <Route path="/free-school-meals/apply/summary">
            <FreeSchoolMealsApplicationSummary
              person={data ? data.portalUser.person : null}
              children={data ?data.portalUser.person.pupilGuardians : null}
              includeChildren={includeChildren}
            />
          </Route>{" "}
          <Route path="/free-school-meals/apply/declaration">
            <FreeSchoolMealsApplicationDeclaration
              agreedDeclaration={agreedDeclaration}
              agreedECS={agreedECS}
              setAgreedDeclaration={setAgreedDeclaration}
              setAgreedECS={setAgreedECS}
            />
          </Route>
        </Switch>
      </div>

      <div className="mt-4">
        <Button as={Link} to="/free-school-meals" style={{backgroundColor:'#FF9900', color:'#FFF'}}>
          {t('Close')}
        </Button>
        <Button as={Link} disabled={isFirstPage()} to={previousPage()} style={{backgroundColor:'#FF9900', color:'#FFF'}}>
          {t('Back')}
        </Button>
        <Button as={Link} disabled={isLastPage()} to={nextPage()} style={{backgroundColor:'#0054A4', color:'#FFF'}}>
          {t('Next')}
        </Button>
        <Button
          loading={updating || creating}
          disabled={updating || creating}
          className={'green'}
          onClick={() => {
            if (benefitClaimId > 0) {
              updateBenefitClaim({
                variables: {
                  id: benefitClaimId,
                  pupilIds: includeChildren,
                  inProgress: 1,
                },
              });
            } else {
              createBenefitClaim({
                variables: {
                  claimType: "FSM",
                  pupilIds: includeChildren,
                  inProgress: 1,
                },
              });
            }
          }}
        >
          {t('Save Progress')}
        </Button>
        <Button
          loading={updating || creating}
          className={'green'}
          disabled={
            updating ||
            creating ||
            !includeChildren.length ||
            !agreedECS ||
            !agreedDeclaration
          }
          onClick={() => {
            if (benefitClaimId > 0) {
              updateBenefitClaim({
                variables: {
                  id: benefitClaimId,
                  pupilIds: includeChildren,
                  inProgress: 0,
                },
              }).then(() => history.push("/free-school-meals"));
            } else {
              createBenefitClaim({
                variables: {
                  claimType: "FSM",
                  pupilIds: includeChildren,
                  inProgress: 0,
                },
              }).then(() => history.push("/free-school-meals"));
            }
          }}
        >
          {t('Submit')}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default FreeSchoolMealsApplication;
