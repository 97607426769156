import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination, Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import dateFormatter from "../../DateFormatter";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LookupCode from "../../LookupCode";
import HelpText from "../../components/HelpText";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../Input";
import {lookupToOptionsTranslation, v} from "../../utils";

const GET_COMMUNICATIONS = gql`
  query GetCommunications(
    $pupilId: Int!
    $page: Int!
    $size: Int!
    $commInOutType: String
    $inOut: String
    $commDate: LocalDate
  ) {
    pupil(pupilId: $pupilId) {
      communications(
        page: $page
        size: $size
        commInOutType: $commInOutType
        inOut: $inOut
        commDate: $commDate
      ) {
        number
        totalPages
        content {
          ... on Communication {
            id
            commDate
            commInType
            commOutType
            commOutTypeDescription
            sender
            recipientName
            summary
            inOut
            senderServiceDefinition {
              person {
                fullName
              }
            }
            serviceDefinition {
              person {
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

function CommunicationsSent({
  lookupCodes,
  communications = [],
  pupilId,
  Dropdowns,
}) {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const searchForm = useForm();
  const submitSearch = ({ commInOutType, commDate }) => {
    fetchMore({
      variables: {
        commInOutType: commInOutType ? commInOutType : "",
        inOut: "I",
        commDate: commDate ? commDate : null,
        size: pageSize,
        page: 0,
        pupilId: pupilId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const { pupil: prevPupil } = prev;
        const pupil = Object.assign({}, prevPupil, {
          communications: fetchMoreResult.pupil.communications,
        });
        return Object.assign({}, prev, { pupil });
      },

    });
  };
  const { loading, data, fetchMore } = useQuery(GET_COMMUNICATIONS, {
    variables: {
      pupilId,
      page: 0,
      size: pageSize,
      commInOutType: "",
      inOut: "I",
      commDate: null,
    },
  });

  if (loading) return <LoadingMessage />;

  if (v(data,"pupil.communications.content",[]).length <= 0)
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input label={t("Date")} name={"commDate"} type="date" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="commInOutType"
                    label={t("Communication")}
                    options={lookupToOptionsTranslation(6068, t)(lookupCodes)}
                  />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                      trigger={
                        <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                commDate: "",
                                commInOutType: ""
                              });
                            }}
                        >
                          <Icon name="times circle" />
                        </Button>
                      }
                      content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <div>
      <FormProvider {...searchForm}>
        <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Input label={t("Date")} name={"commDate"} type="date" />
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdowns
                  name="commInOutType"
                  label={t("Communication")}
                  options={lookupToOptionsTranslation(6068, t)(lookupCodes)}
                />
              </Grid.Column>
              <Grid.Column width={4} style={{ marginTop: "5" }}>
                <br />
                <Button icon color={"green"} type={"submit"}>
                  <Icon name="search" />
                </Button>
                <Popup
                    trigger={
                      <Button
                          icon
                          color={"red"}
                          type="submit"
                          onClick={() => {
                            searchForm.reset({
                              commDate: "",
                              commInOutType: ""
                            });
                          }}
                      >
                        <Icon name="times circle" />
                      </Button>
                    }
                    content={t("Clear")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </FormProvider>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Communication")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Recipient")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Actions")}
              <HelpText
                contentArea="Child Record"
                contentModule="Children"
                contentType="HELP"
                contentItem="Communications - Sent"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.pupil.communications.content
            .filter(({ inOut }) => inOut === "I")
            .map((communication) => (
              <Table.Row key={communication.id}>
                <Table.Cell collapsing>
                  {dateFormatter(communication.commDate)}
                </Table.Cell>
                <Table.Cell collapsing>
                  <LookupCode
                    codeType={6068}
                    codeValue={communication.commInType}
                  />
                </Table.Cell>
                <Table.Cell>
                  {communication.recipientName}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    as={Link}
                    className={"blue"}
                    style={{backgroundColor:'#0054A4', color:'#FFF'}}
                    to={`/children/${pupilId}/communications/sent/${communication.id}`}
                  >
                    {t("View")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.pupil?.communications.number + 1}
              siblingRange={1}
              totalPages={data.pupil?.communications.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const { pupil: prevPupil } = prev;
                    const pupil = Object.assign({}, prevPupil, {
                      communications: fetchMoreResult.pupil.communications,
                    });
                    return Object.assign({}, prev, { pupil });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default CommunicationsSent;
