import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { Link } from "react-router-dom";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";


function ReferralDetails({ lookupCodes }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  setValue(`referralDate`, moment().format("YYYY-MM-DD"));
  setValue(`referralTime`, moment().format("hh:mm"));

  return (
    <React.Fragment>
        <h5>{t("All fields marked with a ")}<span style={{color: 'red'}}>*</span>{t("are mandatory, you will not be able to submit the referral until these fields have been completed.")}</h5>
    <Segment attached="top">
        <h3>{t("Referral Details")}
        <HelpText
            contentArea="Referral Details"
            contentModule="Referrals"
            contentType="HELP"
            contentItem="Referral Details"
            accessLevel="Private"
        />
        </h3>
      </Segment>
      <Segment attached>
        <Form>
          <Input label={t("Referral Date")} type="date" name="referralDate" />
          <Input label={t("Referral Time")} type="time" name="referralTime" />
          <Dropdown
            label={t("Referral Reason")}
            name="referralReason"
            required={true}
            options={lookupCodes
              .filter(({ codeType }) => codeType === 555)
              .map((lookupCode) => ({
                key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
                value: lookupCode.codeValue,
                text: t(lookupCode.codeDescription),
              }))}
          />
          <TextArea label={t("Initial Action")} name="initialAction" />
        </Form>
      </Segment>
      <Segment attached="bottom">
        <Button as={Link} to="/referrals/professionals" style={{backgroundColor:'#0054A4', color:'#FFF'}}>
         {t("Next")}
        </Button>
      </Segment>
    </React.Fragment>
  );
}

export default ReferralDetails;
