import React from "react";
import { Switch, Route } from "react-router-dom";
import MeetingList from "./MeetingList";
import Dropdown from "../../Dropdown";

function MeetingsScreen() {
  return (
    <Switch>
      <Route>
        <MeetingList Dropdowns={Dropdown} />
      </Route>
    </Switch>
  );
}

export default MeetingsScreen;
