import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Message,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { v } from "../../utils";
import LookupCode from "../../LookupCode";
import YesNoFormatter from "../../YesNoFormatter";
import dateFormatter from "../../DateFormatter";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { gql } from "apollo-boost";
import { useToasts } from "react-toast-notifications";
import ordinal from "ordinal";
import { useTranslation } from "react-i18next";
import HelpText from "../../components/HelpText";
import { mergeDeepRight } from "ramda";

const GET_DATA = gql`
  query GetContact($page: Int!, $size: Int!) {
    portalUser {
      person {
        forename
        surname
        personContacts(page: $page, size: $size) {
          number
          totalPages
          content {
            ... on PersonContact {
              commsPreferredMethodOrder
              contactCode
              contactValue
              preferred
              alternative
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

const END_DATE_PERSON_CONTACT = gql`
  mutation EndDatePersonContact(
    $contactCode: String!
    $contactValue: String!
    $startDate: LocalDate!
  ) {
    endDatePersonContact(
      contactCode: $contactCode
      contactValue: $contactValue
      startDate: $startDate
    ) {
      endDate
    }
  }
`;

function ContactList() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const { data, loading, fetchMore } = useQuery(GET_DATA, {
    variables: { page: 0, size: pageSize },
  });

  const { addToast } = useToasts();

  const [endDatePersonContact, { loading: isEndDating }] = useMutation(
    END_DATE_PERSON_CONTACT,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: [
        {
          query: gql`
            {
              portalUser {
                person {
                  personContacts {
                    commsPreferredMethodOrder
                    contactCode
                    contactValue
                    preferred
                    alternative
                    startDate
                    endDate
                  }
                }
              }
            }
          `,
        },
      ],
      onCompleted: () => {
        addToast(t("Contact end dated"), { appearance: "success" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Contact not update"), { appearance: "error" });
        });
      },
    }
  );

  if (loading) return <LoadingMessage />;

  if (v(data, "portalUser.person.personContacts.content", []).length <= 0)
    return (
      <div>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <React.Fragment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Code")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Communication Method")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Preferred")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Alternative")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Start Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("End Date")}</Table.HeaderCell>
            <Table.HeaderCell>
              <HelpText
                contentArea="Profile"
                contentModule="Profile"
                contentType="HELP"
                contentItem="Contact Details"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {v(data, "portalUser.person.personContacts.content", []).map(
            ({
              commsPreferredMethodOrder,
              contactCode,
              contactValue,
              preferred,
              alternative,
              startDate,
              endDate,
            }) => (
              <Table.Row key={`${contactCode}-${contactValue}-${startDate}`}>
                <Table.Cell>
                  <LookupCode codeType={5001} codeValue={contactCode} />
                </Table.Cell>
                <Table.Cell>{contactValue}</Table.Cell>
                <Table.Cell textAlign="center">
                  {!!commsPreferredMethodOrder && (
                    <span className="inline-block rounded p-1 bg-purple-400 w-12 text-center text-white font-bold tracking-wide">
                      {ordinal(commsPreferredMethodOrder)}
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <YesNoFormatter value={preferred} />
                </Table.Cell>
                <Table.Cell>
                  <YesNoFormatter value={alternative} />
                </Table.Cell>
                <Table.Cell>{dateFormatter(startDate)}</Table.Cell>
                <Table.Cell>
                  {!!dateFormatter(endDate) ? (
                    dateFormatter(endDate)
                  ) : (
                    <Button
                      disabled={isEndDating}
                      color={"red"}
                      onClick={() =>
                        endDatePersonContact({
                          variables: {
                            contactCode,
                            contactValue,
                            startDate,
                          },
                        })
                      }
                    >
                      {t("End")}
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.portalUser?.person.personContacts.number + 1}
              siblingRange={1}
              totalPages={data.portalUser?.person.personContacts.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return mergeDeepRight(prev, {
                      portalUser: {
                        person: {
                          personContacts:
                            fetchMoreResult.portalUser.person.personContacts,
                        },
                      },
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <Button className={"purple"} as={Link} to="/profile/contacts/new">
        {t("New")}
      </Button>
    </React.Fragment>
  );
}

export default ContactList;
