import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form, Segment } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import TextArea from "../../TextArea";
import FileInput from "../../FileInput";
import {useMutation, useQuery} from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Dropdown";
import {lookupToOptionsTranslation, v} from "../../utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpText from "../../components/HelpText";
const GET_COMMUNICATIONS = gql`
  query {
    lookupCodes(codeTypes: [6067, 6068]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

function NewCommunicationScreen({ lookupCodes }) {
  const { t } = useTranslation();

  const params = useParams();

  const history = useHistory();

  const { data } = useQuery(GET_COMMUNICATIONS);

  const { addToast } = useToasts();

  const schema = yup.object({
    commInType: yup.string().required("Required"),
    summary: yup.string().required("Required"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [createCommunication, { loading: isCreating }] = useMutation(
    gql`
      mutation CreateCommunication(
        $subjectId: Int!
        $commInType: String!
        $summary: String!
        $parts: [Upload]!
      ) {
        createCommunication(
          subjectId: $subjectId
          commInType: $commInType
          summary: $summary
          parts: $parts
        ) {
          id
        }
      }
    `,
    {
      onCompleted: ({ createCommunication: { id } }) => {
        addToast(t("Communication sent"), { appearance: "success" });
        history.push(`/children/${params.pupilId}/communications/sent/${id}`);
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Communication not sent"), { appearance: "error" });
        });
      },
    }
  );

  const onSubmit = ({ commInType, summary, parts = [] }) => {
    createCommunication({
      variables: {
        subjectId: params.pupilId,
        commInType,
        summary,
        parts: parts.map(({ file }) => file).filter((file) => file !== ""),
      },
      refetchQueries: [
        {
          query: gql`
            query GetCommunications($pupilId: Int!) {
              pupil(pupilId: $pupilId) {
                communications {
                  id
                  commDate
                  commInType
                  commOutType
                  commOutTypeDescription
                  sender
                  recipientName
                  summary
                  inOut
                  serviceDefinition {
                    person {
                      fullName
                    }
                  }
                }
              }
            }
          `,
          variables: {
            pupilId: params.pupilId,
          },
        },
      ],
    });
  };

  return (
    <React.Fragment>
      <Segment attached="top">
        <h3>
          {t("New Communication")}
          <HelpText
              contentArea="Child Record"
              contentModule="Children"
              contentType="HELP"
              contentItem="Communications - New"
              accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Dropdown
                required
                name="commInType"
                label={t("Type")}
                options={lookupToOptionsTranslation(6068,t)(v(data, "lookupCodes", []))}
            />
            <TextArea label={t("Summary")} name="summary" />
            <FileInput name="parts" label="File" />
            <Button type="submit" className={'green'} disabled={isCreating}>
              {t("Send")}
            </Button>
          </Form>
        </FormProvider>
      </Segment>
    </React.Fragment>
  );
}

export default NewCommunicationScreen;
